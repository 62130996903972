<template>
  <div class="main" v-if="projectsStore.project.id">







    <div class="vr-table-plan__div">
      <table class="vr-table-plan">
        <thead>
          <tr>
            <th class="vr-table-plan__fix_no vr-table-plan__fix_no_top" rowspan="2"></th>
            <th class="vr-table-plan__fix_name vr-table-plan__fix_name_top" rowspan="2"></th>
            <th class="vr-table-plan__th_plan vr-table-plan__fix_plan" rowspan="2">Базовый<br/>план</th>
            <th class="vr-table-plan__th_rez vr-table-plan__fix_rez" rowspan="2">Итог<br/>Факт+План</th>
            <th
                v-for="year in range(moment(projectsStore.project.start_at).year(), moment(projectsStore.project.end_at).year()+1)"
                :key="'year'+year"
                class="vr-table-plan__th_year"
                :colspan=" (moment(projectsStore.project.end_at).year()==year ? moment(projectsStore.project.end_at).week() : (WeeksInYear(moment(projectsStore.project.end_at).year())) )
                         - (moment(projectsStore.project.start_at).year()==year ? moment(projectsStore.project.start_at).week() : 1 ) +1 "
            >
<!--              :colspan=" (moment(projectsStore.project.end_at).year()==year ? moment(projectsStore.project.end_at).week() : 53 )
              - (moment(projectsStore.project.start_at).year()==year ? moment(projectsStore.project.start_at).week() : 1 ) +1 "-->
              {{year}}
            </th>
          </tr>
          <tr>
            <template
                v-for="year in range(moment(projectsStore.project.start_at).year(), moment(projectsStore.project.end_at).year()+1)"
                :key="'year'+year+'w'"
            >
              <th
                  v-for="week in range(
                      (moment(projectsStore.project.start_at).year()==year ? moment(projectsStore.project.start_at).week() : 1 ),
                      (moment(projectsStore.project.end_at).year()==year ? moment(projectsStore.project.end_at).week() : (WeeksInYear(moment(projectsStore.project.end_at).year())) )+1
                      )"
                  :key="'y'+year+'w'+week"
                  :class="['vr-table-plan__thmonth',{'vr-table-plan__current-week':currentWeek===year+'-'+week}]"
              >
                <span class="vr-table-plan__month">{{Month(week,year)[2]}}</span><br/>
                {{week}}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
        <tr class="vr-table-plan__separator"><td colspan="9999"></td></tr>
        <template
            v-for="l in lines"
            :key="l.l"
        >
          <tr
              :class="'vr-table-plan__row_color_'+l?.color"
          >
            <td :class="['vr-table-plan__td_no', 'vr-table-plan__fix_no',{'vr-table-plan__no-clr':l.l===''}]">{{l.l}}</td>
            <td :class="[
                'vr-table-plan__td_name',
                'vr-table-plan__fix_name',
                'vr-table-plan__color_n'+l?.color,
                {
                  'vr-table-plan__td_name_div':[30,40,60,70,80,90,100].indexOf(l.n)!==-1
                }
              ]">
              <div v-if="[30,40,60,70,80,90,100].indexOf(l.n)!==-1">{{l.t}}</div>
              <template v-else>{{l.t}}</template>
            </td>
            <template v-if="l.n === 150">
              <gTdInput val="zp1" :store="projectsStore?.project||{}" class="vr-table-plan__td_plan vr-table-plan__fix_plan"/>
              <gTdInput val="command1" :store="projectsStore?.project||{}" class="vr-table-plan__td_plan vr-table-plan__fix_rez"/>
            </template>
            <template v-else-if="l.n === 160">
              <gTdInput val="zp2" :store="projectsStore?.project||{}" class="vr-table-plan__td_plan vr-table-plan__fix_plan"/>
              <gTdInput val="command2" :store="projectsStore?.project||{}" class="vr-table-plan__td_plan vr-table-plan__fix_rez"/>
            </template>
            <template v-else-if="l.n === 170">
              <gTdInput val="zp3" :store="projectsStore?.project||{}" class="vr-table-plan__td_plan vr-table-plan__fix_plan"/>
              <gTdInput val="command3" :store="projectsStore?.project||{}" class="vr-table-plan__td_plan vr-table-plan__fix_rez"/>
            </template>
            <template v-else-if="l.v === 'f1'">
              <gTdInput :key-path="['cost','y0','w0']" val="v4" clr="c4" :store="projectsStore?.project||{}" class="vr-table-plan__td_plan vr-table-plan__fix_plan"/>
              <td class="vr-table-plan__td_rez vr-table-plan__fix_rez vr-table-plan__td_rez_color_n calc-value">{{IntValue(projectsStore?.totalZP)}}</td>
            </template>
            <template v-else-if="l.v === 'f2'">
              <gTdInput :key-path="['cost','y0','w0']" val="v5" clr="c5" :store="projectsStore?.project||{}" class="vr-table-plan__td_plan vr-table-plan__fix_plan"/>
              <td class="vr-table-plan__td_rez vr-table-plan__fix_rez vr-table-plan__td_rez_color_n calc-value">{{IntValue(projectsStore?.totalCommand)}}</td>
            </template>
            <template v-else-if=" l.v === 'f3'">
<!--              <gTdFunc :key-path="['cost','y0','w0']" :val="l.v" :clr="l.c" :store="projectsStore?.project||{}" class="vr-table-plan__td_plan vr-table-plan__fix_plan"/>-->
<!--              <TdCalcRow :costs="projectsStore.project.cost" :view="l.v" />-->
              <td class="vr-table-plan__td_plan vr-table-plan__fix_plan">{{IntValue(
                   (projectsStore?.project?.cost?.y0?.w0?.v2||0)
                  +(projectsStore?.project?.cost?.y0?.w0?.v3||0)
                  +(projectsStore?.project?.cost?.y0?.w0?.v4||0)
                  +(projectsStore?.project?.cost?.y0?.w0?.v5||0)
                  +(projectsStore?.project?.cost?.y0?.w0?.v6||0)
                  +(projectsStore?.project?.cost?.y0?.w0?.v7||0)
                  +(projectsStore?.project?.cost?.y0?.w0?.v8||0)
                  +(projectsStore?.project?.cost?.y0?.w0?.v9||0)
                  +(projectsStore?.project?.cost?.y0?.w0?.v10||0)
              )}}</td>
              <td class="vr-table-plan__td_rez vr-table-plan__fix_rez vr-table-plan__td_rez_color_n calc-value">{{IntValue(projectsStore?.totalRashod)}}</td>
            </template>
            <template v-else-if="l.v === 'f4'">
<!--              <gTdFunc :key-path="['cost','y0','w0']" :val="l.v" :clr="l.c" :store="projectsStore?.project||{}" class="vr-table-plan__td_plan vr-table-plan__fix_plan"/>-->
<!--              <TdCalcRow :costs="projectsStore.project.cost" :view="l.v" />-->
              <td class="vr-table-plan__td_plan vr-table-plan__fix_plan">{{IntValue(
                  (projectsStore?.project?.cost?.y0?.w0?.v1||0)
                  -(projectsStore?.project?.cost?.y0?.w0?.v2||0)
                  -(projectsStore?.project?.cost?.y0?.w0?.v3||0)
                  -(projectsStore?.project?.cost?.y0?.w0?.v4||0)
                  -(projectsStore?.project?.cost?.y0?.w0?.v5||0)
                  -(projectsStore?.project?.cost?.y0?.w0?.v6||0)
                  -(projectsStore?.project?.cost?.y0?.w0?.v7||0)
                  -(projectsStore?.project?.cost?.y0?.w0?.v8||0)
                  -(projectsStore?.project?.cost?.y0?.w0?.v9||0)
                  -(projectsStore?.project?.cost?.y0?.w0?.v10||0)
              )}}</td>
              <td class="vr-table-plan__td_rez vr-table-plan__fix_rez vr-table-plan__td_rez_color_n calc-value">{{IntValue(projectsStore?.totalPrihodMinusRashod)}}</td>
            </template>
            <template v-else-if=" l.v === 'f5'">
              <td :class="[
                      'vr-table-plan__td_plan',
                      'vr-table-plan__fix_plan',
                      'vr-table-plan__td_rez_color_n',
                      'calc-value',
                      {
                        'calc-bad-value': projectsStore?.totalPrihodMinusRashod<0
                      }
                    ]">{{IntValue(projectsStore?.totalPrihodMinusRashod)}}</td>
              <td :class="[
                      'vr-table-plan__td_rez',
                      'vr-table-plan__fix_rez',
                      'vr-table-plan__td_rez_color_n',
                      'calc-value',
                      {
                        'calc-bad-value': projectsStore?.totalPrihodMinusRashod<0
                      }
                    ]"
                 >{{IntValue(projectsStore?.totalPrihodMinusRashod)}}</td>
            </template>
            <template v-else>
              <gTdInput
                  :key-path="['cost','y0','w0']"
                  :val="l.v"
                  :clr="l.c"
                  :week="0"
                  :year="0"
                  :store="projectsStore?.project||{}"
                  class="vr-table-plan__td_plan vr-table-plan__fix_plan"
              />
              <TdCalcRow :costs="projectsStore.project.cost" :view="l.v" />
            </template>

<!--            <td class="vr-table-plan__td_rez vr-table-plan__fix_rez vr-table-plan__td_rez_color_n calc-value">{{ IntValue(projectsStore.project.Costs?.reduce((acc,cur)=>acc+((cur[l.v]||0) - 0),0)) }}</td>-->
            <template
                v-for="year in range(moment(projectsStore.project.start_at).year(), moment(projectsStore.project.end_at).year()+1)"
                :key="'year'+year+'w'"
            >
              <template
                  v-for="week in range(
                      (moment(projectsStore.project.start_at).year()==year ? moment(projectsStore.project.start_at).week() : 1 ),
                      (moment(projectsStore.project.end_at).year()==year ? moment(projectsStore.project.end_at).week() : (WeeksInYear(moment(projectsStore.project.end_at).year())) )+1
                      )"
                  :key="'y'+year+'w'+week"
              >

                <gTdInput


                    :key-path="['cost','y'+year,'w'+week]"
                    :val="l.v"
                    :clr="l.c"
                    :store="projectsStore?.project"
                    :week="week"
                    :year="year"
                    :class="[{'vr-table-plan__current-week':currentWeek===year+'-'+week}]"
                    v-if="l.v[0] !== 'f'"
                />
                <gTdFunc


                    :key-path="['cost','y'+year,'w'+week]"
                    :val="l.v"
                    :clr="l.c"
                    :store="projectsStore?.project||{}"
                    :week="week"
                    :year="year"
                    :class="[{'vr-table-plan__current-week':currentWeek===year+'-'+week}]"
                    v-else
                />
              </template>
            </template>
          </tr>
          <tr class="vr-table-plan__separator" v-if="l.n===100 || l.n===140 "><td colspan="9999"></td></tr>
          <tr class="vr-table-plan__separator" v-if="l.n===140 ">

            <td class="vr-table-plan__fix_no vr-table-plan__no-clr"></td>
            <td class="vr-table-plan__td_prof vr-table-plan__fix_name">Профессия</td>
            <td class="vr-table-plan__td_zp vr-table-plan__fix_plan">ЗП руб в мес</td>
            <td class="vr-table-plan__td_comm vr-table-plan__fix_rez">Командиров<br>руб в день</td>
            <td colspan="9999" class="vr-table-plan__no-clr_ln"></td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>


  </div>
</template>

<script setup>
import {get,set} from 'lodash';
import TdCalcRow from '@/components/Faster/TdCalcRow.vue'
import gTdInput from '@/components/gTdInput.vue'
import gTdFunc from '@/components/gTdFunc.vue'
import {useRoute, useRouter} from 'vue-router'
import {onMounted, ref, onUpdated} from "vue";
import {useProjectsStore} from "@/stores/ProjectsStore.js";
import {range} from "lodash";
import {IntValue} from "@/utils/IntValue";

import moment from "moment/dist/moment";
import ru from "moment/dist/locale/ru";
import {ObjGetKeyPath} from "@/utils/Object.KeyPath.js";


moment.locale('ru',ru);
const editRow = ref(false)

const currentWeek = moment().year()+'-'+moment().week()
const route         = useRoute()

const projectsStore = useProjectsStore();

// const companyStore  = useCompanyStore();

const lines = ref([
  {n:10, l:1,v:'v1',c:'c1',color:1,t:'Поступления всего'},
  {n:20, l:2, v:'v2',c:'c2',color:2,t:'Закупка материалов'},
  {n:30, l:3, v:'v3',c:'c3',color:2,t:'Доставка+хранение материалов'},
  {n:40, l:4, v:'f1',c:'c4',color:3,t:'Зарплата (РП+ПТО+мастера+монтажники)'},
  {n:50, l:5, v:'f2',c:'c5',color:3,t:'Суточные'},
  {n:60, l:6, v:'v6',c:'c6',color:2,t:'Накладные (проезд, проживание и пр.) СОЦИАЛЬНЫЕ'},
  {n:70, l:7, v:'v7',c:'c7',color:2,t:'Расходы на производство (инструмент, одежда, крепёж и пр.)'},
  {n:80, l:8, v:'v8',c:'c8',color:2,t:'Субподрядчики (генподрядчики)'},
  {n:90, l:9, v:'v9',c:'c9',color:2,t:'Прочее. Не вошедшее в другие статьи'},
  {n:100,l:10, v:'v10',c:'c10',color:2,t:'Налоги (НДФЛ 13%, НДС 20%, прибыль 20%)'},

  {n:110,l:11,v:'v1',c:'c1',color:4,t:'Поступления всего'},
  {n:120,l:12,v:'f3',c:'c12',color:4,t:'Расходы всего'},
  {n:130,l:13,v:'f4',c:'c13',color:4,t:'Поступления минус расходы'},
  {n:140,l:14,v:'f5',c:'c14',color:5,t:'БАЛАНС ПРОЕКТА'},

  {n:150,l:15,v:'d1',c:'c15',color:7,t:'Монтажник'},
  {n:160,l:16,v:'d2',c:'c16',color:7,t:'Инженер'},
  {n:170,l:17,v:'d3',c:'c17',color:7,t:'РП'},
])

let months=[
  'янв','фев','мар','апр','май','июн','июл','авг','сен','окт','ноя','дек'
]
function Month(weekNumber,year) {
  let day = (new Date(year, 0, 1)).getDay();
  day = (day===0 ? 7 : day)-1;
  // console.log(ddd)
  const date = new Date(year, 0, 1 + (weekNumber - 1 ) * 7);

  let d1 = new Date(date.getFullYear(), date.getMonth(), date.getDate() - day +(day<=3?0:+7) + 0)
  let d2 = new Date(date.getFullYear(), date.getMonth(), date.getDate() - day +(day<=3?0:+7) + 6)
  let m1 = months[d1.getMonth()];
  let m2 = months[d2.getMonth()];
  return [d1,d2,m1 + (m1!==m2?'/'+m2:'')];
}

function WeeksInYear(year){
  let w = moment(year+"-12-28").isoWeek()
  // console.log(year, w)
  return w
}
function IntVal(value){
  // debugger
  return (value === undefined || value === null || value == '' || value==0)?'':(Intl.NumberFormat('default',
      {
        style: 'decimal',
        maximumFractionDigits: 0
      }).format(value)
      .replace(/ /i,'&#8239;'))
}
onMounted(()=>{
  projectsStore.SelectedProject = route.params.id-0
  projectsStore.api.get.url_params.id = route.params.id
  projectsStore.send('get')
  // vehiclesStore.loadSprav()
})

let cnt = 0

onUpdated(()=>{
  cnt++;
  if(cnt<3){
    document.querySelector('.vr-table-plan__current-week')?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
  }
})

function summa(row){

  // debugger
  return Object.entries(projectsStore.project?.cost)
            .filter(y => y[0]!=='y0' )
            .map(y => Object.values(y[1])
                            .map(w => w[row] || 0))
            .flat()
            .reduce((p, a) => p + (a-0), 0)

}

</script>

<style lang="scss">
.main{padding:8px;}
.vr-table-plan{
  border-collapse: separate;
  border-spacing: 1px;
  //border: 1px solid #ddd;
  //border-left:0;
  background: #ddd;
  position: relative;

  &__hover{position:absolute;display:flex;flex-wrap:nowrap; justify-content:center;align-items:center;background:#ddd;height:34px;top:-3px;left:0;gap:6px;
    &-hide{display:none;}
    &:hover{z-index:4;padding: 0 6px 0 0;background:#c5d4e7;
      .vr-table-plan__hover-hide{display:block;}
    }
  }
  &__div{width:100%;overflow:auto;position: relative;left:0;padding-top:24px;}
  th{white-space: nowrap;background: #e3f3e3;box-shadow: 0 0 0 1px #ccc;}
  td{height:28px;background: #fff;text-align:right;}
  tr:hover td{background-color: #f5f5f5;}
  &__thmonth{min-width:70px;background:#FFF2CC!important;}
  &__th_rez,&__th_plan{background:#FFD966!important;}
  &__month{font-weight: normal;font-size:12px;}
  &__current-week{border-left:5px solid #f00;}
  .vr-table-plan__td_no{background:#fff;padding:3px 6px 3px 0;font-size:12px;position:relative;vertical-align: top;overflow:visible;z-index:3;}
  .vr-table-plan__div_no{text-align:right;display: block;width:328px;min-width:28px;max-width:28px;}
  .vr-table-plan__td_plan{background: #f5f7fd;padding:3px 6px;text-align:right;box-shadow: 0 0 0 1px #c5d4e7;}
  .vr-table-plan__td_rez{background:#f5f7fd;padding:3px 6px;text-align:right;box-shadow: 0 0 0 1px #c5d4e7;;}

  .vr-table-plan__fix_no{width:30px;min-width:30px;max-width:30px; position: -webkit-sticky!important;position: sticky!important;left: 1px;z-index: 3;}
  .vr-table-plan__fix_no_top{background:#fff;box-shadow: -2px 0px 0 2px #fff!important;}
  .vr-table-plan__fix_plan{width:100px!important;min-width:100px!important;max-width:100px!important;position: -webkit-sticky!important;position: sticky!important;z-index: 1;left: 233px;}
  .vr-table-plan__fix_rez{width:100px!important;min-width:100px!important;max-width:100px!important;position: -webkit-sticky!important;position: sticky!important;z-index: 1;left: 334px;box-shadow: 0 0 0 1px #c5d4e7,3px 0 0px 0px rgba(0,0,0,.1)}
  .vr-table-plan__fix_name {width: 200px;min-width: 200px;max-width: 200px;position: -webkit-sticky !important;position: sticky !important;z-index: 2;left: 32px;}
  .vr-table-plan__fix_name_top{background:#fff;box-shadow: -2px 0px 0 2px #fff!important;}
  .vr-table-plan__td_name {
    padding:0;
    vertical-align: top;
    & > div {
      padding: 3px 6px;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > div:hover {
      background: #ddd;
      overflow:visible;
      position:absolute;
      z-index: 1;
      width:400px;
      display: block;
      -webkit-line-clamp: 1;
      -webkit-box-orient: initial;
      text-overflow: unset;
    }
  }
}
.dt-from-to{display:flex;gap:12px;margin:0 0 8px 0;}

.vr-table-plan__row_color_1{
  td{background: #FBE4D5;}
  .vr-table-plan__fix_name{background: #F7CAAC;}
  .vr-table-plan__fix_plan{background: #F7CAAC;}
  .vr-table-plan__fix_rez{background: #F7CAAC;}
  &:hover{
    td{background: #efd5c2 !important;}
    .vr-table-plan__fix_no{background: #fff !important;}
    .vr-table-plan__fix_name{background: #e8b796 !important;}
    .vr-table-plan__fix_plan{background: #e8b796!important;}
    .vr-table-plan__fix_rez{background: #e8b796!important;}
  }
}
.vr-table-plan__row_color_2{
  td{background: #eff3ec !important;}
  .vr-table-plan__td_no{background: #fff !important;}
  .vr-table-plan__fix_name{background-color: #E3EFD9 !important;}
  .vr-table-plan__fix_plan{background-color: #E3EFD9 !important;color:#aaa;}
  .vr-table-plan__fix_rez{background-color: #E3EFD9 !important;}
  &:hover{
    td{background: #f6f8f3 !important;}
    .vr-table-plan__td_no{background: #fff !important;}
    .vr-table-plan__fix_name{background-color: #d8eacc !important;}
    .vr-table-plan__fix_plan{background-color: #d8eacc !important;color:#aaa;}
    .vr-table-plan__fix_rez{background-color: #d8eacc !important;}
  }
}
.vr-table-plan__row_color_3{
  td{background: #f1f1f1;color:#305496;}
  .vr-table-plan__fix_name{background-color: #dbecce;}
  .vr-table-plan__fix_plan{background-color: #dcead1;color:#aaa;}
  .vr-table-plan__fix_rez{background-color: #dcead1;color:#305496;font-weight: bold}
  &:hover{
    .vr-table-plan__fix_name{background-color: #d9eac4;}
    .vr-table-plan__fix_plan{background-color: #d9eac4;color:#aaa;}
    .vr-table-plan__fix_rez{background-color: #d9eac4;color:#305496;font-weight: bold}
  }
}
.vr-table-plan__row_color_4{
  td{background: #F2F2F2;color:#305496;}
  .vr-table-plan__fix_name{background: #D9D9D9;color:#000;}
  .vr-table-plan__fix_plan{background: #D9D9D9;color:#7F7F7F;}
  .vr-table-plan__fix_rez{background: #D9D9D9;}
  &:hover{
    td{background-color: #e1dddd !important;}
    .vr-table-plan__fix_name{background-color: #c4c0c0 !important;color:#000;}
    .vr-table-plan__fix_plan{background-color: #c4c0c0!important;color:#7F7F7F;}
    .vr-table-plan__fix_rez{background-color: #c4c0c0!important;}
  }
}
.vr-table-plan__row_color_5{
  td{background-color: #D9D9D9;}
  .vr-table-plan__fix_name{background-color: #D9D9D9;font-weight: bold;}
  .vr-table-plan__fix_plan{background-color: #D9D9D9;color:#aaa;font-weight: bold;}
  .vr-table-plan__fix_rez{background-color: #D9D9D9;font-weight: bold;}
  &:hover{
    td{background-color: #cbcbcb!important;}
    .vr-table-plan__fix_name{background-color: #cbcbcb!important;}
    .vr-table-plan__fix_plan{background-color: #cbcbcb!important;}
    .vr-table-plan__fix_rez{background-color: #cbcbcb!important;}
  }
}
.vr-table-plan__row_color_6{
  td{background-color: #FFF2CD;}
  .vr-table-plan__fix_name{background-color: #FFF2CD;}
  .vr-table-plan__fix_plan{background-color: #FFF2CD;color:#aaa;}
  .vr-table-plan__fix_rez{background-color: #FFF2CD;}
  &:hover{
    td{background-color: #f1e3ba!important;}
    .vr-table-plan__fix_name{background-color: #f1e3ba!important;}
    .vr-table-plan__fix_plan{background-color: #f1e3ba!important;}
    .vr-table-plan__fix_rez{background-color: #f1e3ba!important;}
  }
}
.vr-table-plan__row_color_7{
  td{background-color: #D9E1F2;color:#000;box-shadow:0 0 0 1px #fff;}
  .vr-table-plan__fix_name{background-color: #B4C6E7;color:#000;box-shadow:0 0 0 1px #fff;}
  .vr-table-plan__fix_plan{background-color: #B4C6E7;color:#000;box-shadow:0 0 0 1px #fff;}
  .vr-table-plan__fix_rez{background-color: #B4C6E7;color:#000;box-shadow:0 0 0 1px #fff;}
  &:hover{
    td{background-color: #d3dcee !important;box-shadow:0 0 0 1px #fff;}
    .vr-table-plan__fix_name{background-color: #aec1e5 !important;box-shadow:0 0 0 1px #fff;}
    .vr-table-plan__fix_plan{background-color: #aec1e5!important;box-shadow:0 0 0 1px #fff;}
    .vr-table-plan__fix_rez{background-color: #aec1e5!important;box-shadow:0 0 0 1px #fff;}
  }
}
.vr-table-plan__th_year{background:#FFD966!important;}

.vr-table-plan td.vr-table-plan__td_name{text-align:left;padding:3px 6px;}
.vr-table-plan td.vr-table-plan__td_name.vr-table-plan__td_name_div{padding:0;}
.vr-table-plan td.vr-table-plan__td_name.vr-table-plan__td_name_div:hover{position:relative;z-index:10000}

.vr-table-plan__no-clr{background:#fff!important;box-shadow: -1px -2px 0  2px #fff;z-index:3;
  tr:hover &{background:#fff!important;}
}
.vr-table-plan__separator .vr-table-plan__no-clr{box-shadow: -1px -1px 0  1px #fff;}
.vr-table-plan__separator .vr-table-plan__no-clr_ln{box-shadow: -1px -1px 0  1px #fff;}

//.vr-table-plan__td_color_0{background:#E2EFDA!important;
//  tr:hover &{background: #d1e3c9 !important;}
//}
.td-input__number.vr-table-plan__td_wk_0{color:#c00;}


.vr-table-plan tr {
  td.vr-table-plan__td_color_1 {background:#fcc!important; color:#000!important;}
  td.vr-table-plan__td_color_2 {background: #fec !important;color: #000 !important;}
  td.vr-table-plan__td_color_3 {background: #82e574 !important;color:#000!important;}

  &:hover {
    td.vr-table-plan__td_color_1 {background: #fac7c7 !important;}
    td.vr-table-plan__td_color_2 {background: #f3dfb9 !important;}
    td.vr-table-plan__td_color_3 {background: #43c533 !important;}
  }
}

.calc-value{color:#305496;font-weight: bold;}

.vr-table-plan__separator{background:#fff!important;
  td{background:#fff!important;height:8px;box-shadow: -1px 0 0  0px #fff;}
}

td.vr-table-plan__td_prof{background:#8EA9DB!important;padding:3px 6px;text-align:left;font-weight:bold;}
td.vr-table-plan__td_zp{background:#8EA9DB!important;padding:3px 6px;text-align:center;font-weight:bold;}
td.vr-table-plan__td_comm{background:#8EA9DB!important;padding:3px 6px;text-align:center;font-weight:bold;box-shadow: 0 0 0 1px #c5d4e7, 3px 0 0px 0px rgb(0 0 0 / 10%);}

.calc-bad-value{
  &, tr:hover &, .vr-table-plan__row_color_5:hover &{
    color:#c00!important;
    //background-color:#fcc!important;
    //box-shadow: 0 0 0 1px #900!important;
  }
}


</style>
