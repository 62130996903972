<template>
  <div class="fixed fixed-center bg-grey-1 page-login">
    <form v-on:submit.prevent="authStore.submit">

      <q-card square  class="flex-center text-center login_card" v-if="authStore.action===''">
        <span class="svg-icon svg-icon-logo" style="margin-bottom:42px"><svg><use xlink:href="#svg-logo"></use></svg></span>
        <q-input type="text" v-model.trim="authStore.email" label="Email" outlined required autofocus style="margin-bottom:12px" />
        <q-input type="password" v-model="authStore.password" label="Пароль" outlined required style="margin-bottom:12px"/>
        <div class="flex justify-between items-center" style="margin-bottom:32px">
          <q-checkbox v-model="authStore.remember" label="Запомнить"/>
<!--          <div class="login_link" @click="authStore.action='forgot'">Восстановить</div>-->
        </div>
        <q-btn type="submit" class="fit" color="primary" size="lg" :loading="authStore.loading">Войти</q-btn>
<!--        <div class="login_link" @click="authStore.action='reg'" style="margin-top:32px">Регистрация</div>-->
<!--        <div class="login_link" @click="authStore.logined=true" style="margin-top:32px">ТЕСТ: типа авторизовался</div>-->
      </q-card>

      <q-card square  class="flex-center text-center login_card" v-else-if="authStore.action==='forgot'">
        <span class="svg-icon svg-icon-logo" style="margin-bottom:42px"><svg><use xlink:href="#svg-logo"></use></svg></span>
        <q-input type="text" v-model.trim="authStore.email" label="Email" outlined required autofocus style="margin-bottom:32px" />
        <q-btn type="submit" class="fit" color="primary" size="lg" :loading="authStore.loading" style="margin-bottom:32px">Восстановить</q-btn>
        <div class="login_link" @click="authStore.action=''">Вход</div>
      </q-card>

      <q-card square  class="flex-center text-center login_card" v-if="authStore.action==='reg'">
        <span class="svg-icon svg-icon-logo" style="margin-bottom:42px"><svg><use xlink:href="#svg-logo"></use></svg></span>
        <q-input type="text" v-model.trim="authStore.email" label="Логин" outlined required autofocus style="margin-bottom:12px" />
        <q-input type="password" v-model="authStore.password" label="Пароль" outlined required style="margin-bottom:12px"/>
        <q-input type="password" v-model="authStore.password2" label="Подтвердите пароль" outlined required style="margin-bottom:32px"/>
        <q-btn type="submit" class="fit" color="primary" size="lg" :loading="authStore.loading">Регистрация</q-btn>
        <div class="login_link" @click="authStore.action=''" style="margin-top:32px">Вход</div>
      </q-card>

    </form>
  </div>
</template>

<script setup>
import { useAuthStore } from "@/components/Auth/AuthStore.js";
const authStore = useAuthStore();

</script>

<style lang="stylus">
.login_card{max-width: 400px; width:95vw; padding:50px}
.login_link{color: #2D9CDB;text-decoration: underline dashed #2D9CDB; cursor: pointer;}
.page-login .svg-icon svg{width:45%;height:45px;}
</style>
