<template>
  <td
      v-if="props.type==='number-inline'"
      @dblclick="editIt()"
      :class="[
          'td-input',
          'td-input__number',
          color?'vr-table-plan__td_color_'+color : '',
          'vr-table-plan__td_wk_'+thisValue.wk,
           {
             'td_unsaved':get(props.store,[...props.keyPath,'OLD___'+props.val])!==undefined
           }
           ]"
      ref="tdRef"
      contenteditable="true"
      @blur="save"

      @keydown.enter.prevent="save"
      @keydown.esc.prevent="cancel"
      v-on="listeners"
      @contextmenu="handler($event)"
      @click="changeColor($event)"
  >

    {{
      props.isInt
      ? IntValue(thisValue.v)
      : thisValue.v
    }}
  </td>
  <td
      v-else
      @dblclick="editIt()"
      class="td-input"
      ref="tdRef"
      contenteditable="true"
  >
    <template v-if="!editing">
        {{props.isInt?IntValue(thisValue.v):thisValue.v}}
      </template>
    <template v-else>
      <input
          v-model="value"
          class="td-input__input"
          @blur="editing=false"

          @keydown.enter.prevent="save"
          @keydown.esc.prevent="cancel"
      />
    </template>
  </td>
</template>

<script setup>
import {get,set} from 'lodash';
import {computed, onMounted, ref} from "vue";
import {IntValue} from "@/utils/IntValue";
import {log} from "../utils/log";
import {useProjectsStore} from "@/stores/UsersStore";

const projectsStore = useProjectsStore();

const props = defineProps({
  store: {type: Object, default: ()=>({}),},
  type:  {type: String, default: "number-inline", },
  year:  {type: [String,Number], default: 0, },
  week:  {type: [String,Number], default: 0 },
  keyPath: {type: [String,Array], default: [], },
  isInt:{ type:Boolean,default:true},
  val:{ type:String, default:''},
  clr:{type:String,default:''},
});
/*const thisValue = computed(()=>{
  let v = get(props.store,props.keyPath);
  // log('thisValue',props.val)
  return v===undefined ? 0 : props.val[0] === 'f'
      ? ({
        // ЗП
          f1: (f)=>(((f.d1||0)-0)*props.store.zp1)+     (((f.d2||0)-0)*props.store.zp2)+     (((f.d3||0)-0)*props.store.zp3) ,
        // суточные
        f2: (f)=>(((f.d1||0)-0)*props.store.command1)+(((f.d2||0)-0)*props.store.command2)+(((f.d3||0)-0)*props.store.command3) ,

        // Расходы всего (без налогов и премии)
        f3: (f)=>
                (((f.d1||0)-0)/4*(props.store?.zp1||0))+
                (((f.d2||0)-0)/4*(props.store?.zp2||0))+
                (((f.d3||0)-0)/4*(props.store?.zp3||0))+
                (((f.d1||0)-0)*5*(props.store?.command1||0))+
                (((f.d2||0)-0)*5*(props.store?.command2||0))+
                (((f.d3||0)-0)*5*(props.store?.command3||0))
              +(f.v2-0)
              +(f.v3-0)
              +(f.v4-0)
              +(f.v5-0)
              +(f.v6-0)
              +(f.v7-0)
              +(f.v8-0)
              +(f.v9-0)
              +(f.v10-0)
          ,
        // Поступления минус расходы
          f4: (f)=>(f.v1-0)
              - (((f.d1||0)-0)/4*(props.store?.zp1||0))
              - (((f.d2||0)-0)/4*(props.store?.zp2||0))
              - (((f.d3||0)-0)/4*(props.store?.zp3||0))
              - (((f.d1||0)-0)*5*(props.store?.command1||0))
              - (((f.d2||0)-0)*5*(props.store?.command2||0))
              - (((f.d3||0)-0)*5*(props.store?.command3||0))
              -(f.v2-0)
              -(f.v3-0)
              -(f.v4-0)
              -(f.v5-0)
              -(f.v6-0)
              -(f.v7-0)
              -(f.v8-0)
              -(f.v9-0)
              -(f.v10-0)
          ,
          f5: (f)=>(f.v1-0)
              - (((f.d1||0)-0)/4*(log('ZP1',props.store?.zp1)||0))
              - (((f.d2||0)-0)/4*(props.store?.zp2||0))
              - (((f.d3||0)-0)/4*(props.store?.zp3||0))
              - (((f.d1||0)-0)*5*(props.store?.command1||0))
              - (((f.d2||0)-0)*5*(props.store?.command2||0))
              - (((f.d3||0)-0)*5*(props.store?.command3||0))
              -(f.v2-0)
              -(f.v3-0)
              -(f.v4-0)
              -(f.v5-0)
              -(f.v6-0)
              -(f.v7-0)
              -(f.v8-0)
              -(f.v9-0)
              -(f.v10-0)
          ,
      }[props.val])(v)
      : props.val===''
          ? v
          : (v[props.val]||0)
})*/

const thisValue = computed(()=>{
  let v;
  let path = []
  if(props.keyPath[1]!== undefined){
    path = [props.keyPath[1],props.keyPath[2]]
  }
  let va = get(projectsStore.itogi,path);
  if(props.val[0] === 'f'){
    // console.log("gTdFunc.vue::34 ", v);
    return {
      v:    va === undefined ? 0 : va[
          {
            f1: 'zp',
            f2: 'cmd',
            f3: 'rashod',
            f4: 'delta',
            f5: 'balance',
          }[props.val]
          ]
      , wk: va?.wk||2
    }
  }

  // if(props.val.startsWith('zp')){
  //   v = get(props.store,props.val);
  // }else{
  // }
  v = get(props.store,[...props.keyPath,props.val]);
  return {
    v: v === undefined ? 0 : (v || 0)
    , wk: va?.wk??2
  }
})
const value = ref('')
const editing = ref(false)
const tdRef = ref(null)
const path = ref([])
const pathColor = ref([])
function listeners(e){
  console.log(e,tdRef,value)
}

function editIt(){
  editing.value=true;
  setTimeout(()=>{ tdRef.value.querySelector('.td-input__input').focus()},100)
}

function changeColor(e){
  if(e.ctrlKey || e.metaKey){
    handler(e)
  }
}



function save(e){
  let realValue;
  // debugger
  if(props.type==='number-inline'){
    e.target.blur();
    let val = tdRef.value.innerText.replace(/,/ig,'.').replace(/[^0-9.]+/ig,'')
    realValue = val===''?'': val - 0
    // console.log(realValue)
    value.value = realValue
    if(realValue===0){
      e.target.innerText = ''
    }
    // SaveToCost(value)
    let old = get(props.store,[...props.keyPath,'OLD___'+props.val])
    if((old||0) !== (value||0) ){
      set(props.store,[...props.keyPath,'OLD___'+props.val],old||0)
    }
    set(props.store,path.value,value)
    projectsStore.needSave = true
    if(props.val.startsWith('zp')||props.val.startsWith('command')){
      projectsStore.needSaveProject = true
    }
    projectsStore.save()
  }
  editing.value=false;
}

const color = computed(()=>get(props.store,pathColor.value)||0)
function cancel(e){
  e.target.blur();
  value.value = get(props.store, path.value)
  editing.value=false;
}

onMounted(() => {
  path.value = props.val==='' ? props.keyPath : [...props.keyPath,props.val]
  pathColor.value = props.clr==='' ? props.keyPath : [...props.keyPath,props.clr]
  value.value = get(props.store, [...props.keyPath,props.val])
})
function handler(e){
  e.preventDefault();
  let v = get(props.store, pathColor.value)
  console.log('ЦВЕТ',v)
  let col = ( v || 0 ) + 1;
  if (col>=4){col=0}
  // if(v!==undefined){
    set(props.store, [...props.keyPath,'OLD___'+props.clr], v)
  // }
  console.log('ЦВЕТ был',v, 'стал',col)
  set(props.store, pathColor.value, col)
  save(e);
}
</script>

<style lang="stylus">
.td-input{
  &[contenteditable="true"]{
    outline: 0px;
    &:focus{
      outline: 1px solid #0d47a1;
    }
  }

  &__input{
    height: 28px;
    padding:0 3px ;
    width:calc(100% + 2px);
    margin:-1px ;
    border:0;
    outline: 1px solid #0d47a1;
    border-radius: 0;
    text-align: right;
    -moz-appearance:textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
  &__number{white-space: nowrap;}
}
.td_unsaved{position:relative;
  &::after{
    content:"";
    position:absolute;
    top:0;right:0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 0;
    border-color: transparent #ff0000 transparent transparent;
  }
}
</style>
