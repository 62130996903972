import Configs from "@/Configs";
import { useAuthStore } from "@/components/Auth/AuthStore.js";
import {has,get,set,update,concat,defaultsDeep} from 'lodash';
import {ref,computed} from "vue"
import { StoreDefinition } from 'pinia'

interface ApiRequest {
  /**
   * Включить предзакрузку?
   * true - для всех datas
   * 'project,user' - только для project и user
   */
  preload?: string | true;
  /**
   * URL запроса. При этом, в строке может быть задано /path/with/:key
   * Ключ key будет взят из url_params
   */
  url?: string;
  /**
   * Параметры URL, которые будут заменять ключи в формате /path/with/:key или /path/with/{key}
   */
  url_params?: any;
  /**
   * Если какой-то параметр не найден, тогда отпралять? По умолчанию false - не отправлять.
   */
  url_send_undefined?: boolean;
  method?: string;
  /**
   * Get Параметры
   */
  params?: any;
  headers?: any;
  /**
   * если объект, то преобразуется в json
   */
  body?: any;
  /**
   * Домен вида https://example.com
   */
  domain?: string;
  /**
   * Куда будем сохранять пришедшее и как будем обрабатывать. Если не задано, то и не обрабатываем
   *
   * Нельзя забывать, что для записи в ref переменную в сторе надо прописывать ключ value, например projects.value
   * @example
   *     [
   *       {res: 'item.project', to:'data.project',act:'save',}, // Сохранить и если нету, то пропустить.
   *       {res: 'item.project', to:'data.project',act:'save',default: '',}, // Сохранить и если нету, то поставить default значение
   *       {res: 'item.project', to:'data.project',act:'add', default: [],}, // Добавить
   *       {res: 'item.project', to:'data.project',act:(oldValue,newValue)=>{}, default: [],}, // Обновить используя функцию
   *       {res: 'item.project', to:'storeName:data.project',act:'save',}, // Сохранить и если нету, то пропустить.
   *     ]
   *
   * @example
   *    save_to: "=",
   *    // означает, что создастся массив вида
   *    // где  будут прописаны все ключи из объекта datas, по которому создаются все данные
   *    [{res: 'projects', to:'projects.value'}]
   *
   */
  save_to?: string | SaveTo[];

}

enum SaveToActions {
  save = 'save',
  add = 'add',
}
interface SaveTo {
  /**
   * из пришедшего запроса json путь, например 'a[0].b.c', ['x', '0', 'y', 'z']
   *
   * @defaultValue ''
   */
  res?: string | (string | number)[];
  /**
   * В какую переменную сохранять
   */
  to?: string | (string | number)[];
  /**
   * Имя стора в который записать. По умолчанию пусто. Значит в текущий.
   *
   * @defaultValue ''
   */
  store?: StoreDefinition;
  /**
   * Действие, которое надо совершить.
   *
   * @defaultValue 'save'
   */
  act?: SaveToActions | ((res: string | (string | number)[])=>any);
  /**
   * Если ничего не вернуло, то будет записано это значение. Если это значение не задано, тогда запись будет пропущена.
   */
  default?:any;
}
interface ApiRequests {
  [key: string]: ApiRequest;
  // save?: PreloadDataRequest;
  // delete?: PreloadDataRequest;
}

/**
 * Функция для подгрузки данных
 *
 * @param options
 * @returns {any}
 * @constructor
 */
export function API(datas:Object = {data: ''}, options:ApiRequests={}, baseOptions:ApiRequest={}){
  const authStore = useAuthStore();
  let opt: ApiRequests = defaultsDeep({},options)
  let rezObject = {
    api: ref(opt),
  }


  function p_set(store,to,value,saveAsLoading=false){
    let _to = to||''
    let start = _to.split('.')[0]
    let storeData = store!==undefined?store:rezObject
    set(storeData,_to,value) // todo добавить сохранение еще и при указании стора
    if(saveAsLoading){
      storeData[start + 'Loading'].value = false
      storeData[start + 'Error'].value = false
      storeData[start + 'Loaded'].value = true
    }
  }
  function p_add(store,to,value,saveAsLoading=false){
    let _to = to||''
    let start = _to.split('.')[0]
    let storeData = store!==undefined?store:rezObject
    update(storeData,_to,(e)=>concat(e,value)) // todo добавить сохранение еще и при указании стора
    if(saveAsLoading){
      storeData[start + 'Loading'].value = false
      storeData[start + 'Error'].value = false
      storeData[start + 'Loaded'].value = true
    }
  }
  function setData(save_to: string | SaveTo[], JSONvalue: any, saveAsLoading=false) {
    if(save_to==='='){
      save_to = Object.keys(datas).map(e=>({res:e,to:e+'.value'}))
    }
    save_to.forEach((s)=>{
      if(has(JSONvalue,s.res)){ // если такой json путь существует, то будем сохранять
        if(typeof(s.act)==='function'){
          let fnRez = s.act(get(JSONvalue,s.res))
          if('to' in s){
            p_set(s.store,s.to,fnRez,saveAsLoading)
          }
        }else if(s.act===SaveToActions.add){
          p_add(s.store,s.to,get(JSONvalue,s.res),saveAsLoading)
        }else{ // SaveToActions.save
          p_set(s.store,s.to,get(JSONvalue,s.res),saveAsLoading)
        }
      }else{
        if('default' in s){ // Если не существует путь и есть default значения, то присваиваем его
          p_set(s.store,s.to,s.default,saveAsLoading)
        }
      }
    })
  }

  /**
   * Отправит запрос по конкретным опциям
   * @param options
   */
  function load(options:ApiRequest={}){
    let opt2: ApiRequest = defaultsDeep({},options,baseOptions)
    // debugger
    let domain = (opt2.domain||'') == '' ? window.location.origin : opt2.domain;
    let url:any = domain + opt2.url;
    url = new URL(url)
    let sendOk = true
    url.pathname = url.pathname.replace(/(?:\{([a-z0-9_-]+)\}|:([a-z0-9_-]+))/ig,(str,p1,p2)=>{
      try{
        return opt2?.url_params[p1||p2]
      }catch(e){
        sendOk = options.url_send_undefined || false
        return ''
      }
    })
    if(sendOk){
      statusLoading(options,{Loading:true})
      url = url.toString()
      console.log(url)
      authStore.send(
          opt2.method||'GET',
          url + ('params' in opt2 ?(
              (url.indexOf("?") >= 0?'&':'?')+
              (new URLSearchParams(opt2.params)).toString()
          ):''),
          opt2.body,
      ).then((data)=>{
        statusLoading(options,{Loading:false,Error:false,Loaded:true})
        if (data.error===false && data.response.status===200 && ('save_to' in opt2)){
          setData( opt2.save_to, data.result,true)
        }
      })
    }
  }

  /**
   * сохраняет статусы для всех preload переменных
   *
   * @param options
   * @param values
   */
  function statusLoading(options:ApiRequest={},values:object={}){
    if(options.preload===undefined){return}
    let keys = options.preload===true ? Object.keys(datas):((options.preload||'').split(','))
    let vals = Object.keys(values)
    keys.forEach(key=>{
      vals.forEach(v=>{
        if((key + v) in rezObject){
          rezObject[key + v] = {}
        }
        rezObject[key + v].value = values[v]
      })
    })
  }

  // // Сохраняем все методы из API
  // Object.keys(opt).forEach(key=>{
  //   rezObject[key] = load
  // })

  /**
   * Загрузит из подходящего по опциям
   *
   * @param dataKey
   * @param options
   */
  function preload(dataKey:string,options:ApiRequests){
    let l = Object.values(options).find(o=> o.preload===undefined ? false : (o.preload===true || (o.preload||'').split(',').indexOf(dataKey)!==-1))
    if(l !== undefined){
      load(l)
    }
  }
  // Создаем переменные с данными и статусами загрузки
  Object.keys(datas).forEach(key=>{
    rezObject['_'+key] = ref(datas[key])
    rezObject[key+'Loaded'] = ref(false)
    rezObject[key+'Error'] = ref(false)
    rezObject[key+'Loading'] = ref(false)
    rezObject[key+'Stop'] = ref(false)
    rezObject[key] = computed({
      get(){
        if(rezObject[key+'Loaded'].value!==true && rezObject[key+'Loading'].value!==true && rezObject[key+'Stop'].value!==true){
          preload(key,rezObject.api.value)
        }
        return rezObject['_'+key].value
      },
      set(newValue){
        if(('_'+key) in rezObject){
          rezObject[ '_'+key ] = {}
        }
        rezObject['_'+key].value = newValue
      },
    })
  })

  rezObject.send = (apiKey: string,opt:ApiRequest={})=>{
    let opt2 = defaultsDeep({},opt,rezObject.api.value[apiKey])
    load(opt2)
  }

  return rezObject
};
