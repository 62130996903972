<template>
  <td class="vr-table-plan__td_rez vr-table-plan__fix_rez vr-table-plan__td_rez_color_n calc-value">
    {{ summa }}
  </td>
</template>

<script setup>
import {computed} from "vue";
import {IntValue} from "@/utils/IntValue";

const props = defineProps({
  costs: {type: Array, default: ()=>([]),},
  view: {type: String, default: "v1",}
});
const summa = computed(()=> {

  // debugger
  return IntValue(
      Object.entries(props.costs)
            .filter(y => y[0]!=='y0' )
            .map(y => Object.values(y[1])
                            .map(w => w[props.view] || 0))
            .flat()
            .reduce((p, a) => p + (a-0), 0)
  )
})
</script>
