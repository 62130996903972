// import Projects from "./Projects.vue";
import ProjectsList from "./ProjectsList.vue";
import ProjectsView from "./ProjectsView.vue";

const ProjectsRoutes = [
  {path: '/projects', name:"ProjectsMain", components: {default: ProjectsList},},
  {path: '/projects/projects', name:"Projects", components: {default: ProjectsList},},
  {path: '/projects/projects/:id', name:"ProjectView", components: {default: ProjectsView},},
];
export default ProjectsRoutes;
