
export function IntValue(value){
  // debugger
  return (value === undefined || value === null || value == '' || value==0)
    ? ''
    : ( Intl.NumberFormat('default',
        {
                style: 'decimal',
                maximumFractionDigits: 0
              })
            .format(value)
            .replace(/ /i,'&#8239;')
      )
};
