<template>
  <div class="main">
    <div class="add_project_title">{{route.params.id===undefined ? 'Добавить пользователя':((projectsStore.new_user?.id || -1) === (projectsStore.user?.id || 0) ? 'Мой профиль' :  'Профиль пользователя')}}</div>
    <div class="">
      <div class="q-gutter-md" style="width: 355px;margin:0 auto;">
        <q-input v-model="projectsStore.new_user.fio" label="ФИО" autofocus/>
        <q-input v-model="projectsStore.new_user.email" label="E-mail"/>
        <q-input v-model="projectsStore.new_user.passwd" label="Пароль" type="password"/>
        <q-input v-model="projectsStore.new_user.note" label="Примечание" type="textarea" autogrow/>
        <q-checkbox v-model="projectsStore.new_user.is_admin" label="Администратор">
          <q-tooltip>Тот кто может раздавать права доступа и изменять пароли пользователей</q-tooltip>
        </q-checkbox>
        <q-checkbox v-model="projectsStore.new_user.is_published" label="Активирован">
          <q-tooltip>Тот кто может раздавать права доступа и изменять пароли пользователей</q-tooltip>
        </q-checkbox>

        <q-toolbar class="pad0 q-gutter-x-md ">
          <q-btn label="СОХРАНИТЬ" color="primary" :loading="projectsStore.new_userLoading"
                 @click="
                  projectsStore.send('set_user',{body: pick(projectsStore.new_user, 'id,fio,email,passwd,note,is_admin,is_published'.split(','))});
                  router.push({ name: 'Users'});
                 "
                 v-close-popup/>
          <q-btn label="ОТМЕНИТЬ" @click="projectsStore.resetSetUser();router.push({ name: 'Users'});"/>
        </q-toolbar>
      </div>
    </div>
    <div class="q-gutter-md" style="width: 355px;margin:60px auto 0 auto;">
      <div class="add_project_title">Руководитель проектов</div>
      <router-link
          v-for="p in (projectsStore.projects||[]).filter(pp=>pp.adm_user_id===(route.params?.id||0)-0)"
          :key="'p'+p.id+'u'"
          :to="{ name: 'ProjectView', params: { id: p.id }}"
          class="user_project"
      >{{p.name}} </router-link>
    </div>
  </div>
</template>

<script setup>
import {useRoute,useRouter} from 'vue-router'
import {onMounted} from "vue";
import {useProjectsStore} from "@/stores/ProjectsStore.js";
import {pick} from "lodash";

const route         = useRoute()
const router        = useRouter()
const projectsStore = useProjectsStore();


onMounted(()=>{
  projectsStore.send('get_user',{ url_params:{ id: route.params?.id-0 } })
})

</script>

<style lang="scss">
.admins-add {
  border-radius: 0 10px 10px 10px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  max-width: 800px;
  width: 90vw
}

.admins-add-title {
  flex: 1 0 100%;
  padding: 40px 0 0 40px;
  font-size: 20px;
}

.admins-add-c1 {
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 40px 40px 40px;
}

.admins-add-c2 {
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 40px 40px 40px;
  border-left: 1px solid #F2F2F2;
}

.q-table-vert {
  width: 100%;

  td {
    padding: 6px 12px;
    border-bottom: 1px solid #F2F2F2;
    text-align: left;

    & > div {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;

      .block {
        font-size: 12px;
        text-transform: none;
      }
    }
  }

  td:first-child {
    font-weight: bold;
  }
}

.vehicle_cards_row {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom:24px;
}

.vehicle_card1 {
  flex: 1 0 200px;
}

.vehicle_card2 {
  flex: 3 0 200px;
}

.vehicle_card3 {
  flex: 4 0 200px;
}

.vehicle_card1_title {
  font-size: 23px;
  padding: 4px 4px 4px 4px;

  b {
    color: #2D9CDB;
  }
}

.vehicle_card_acttable {
  width: 100%;

  td {
    padding: 3px 6px;
    vertical-align: top;

    & > div {
      display: flex;
      gap: 6px;
      flex-wrap: wrap;

      & > * {
        flex: 1 0 auto;
      }
    }

    .block {
      font-size: 12px;
      text-transform: none;
    }

    &:first-child {
      border-right: 1px solid #f2f2f2;
    }
  }
}
</style>
