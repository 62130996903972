import { createApp } from 'vue'
import { Quasar,Notify,  } from 'quasar'
import quasarLang from 'quasar/lang/ru'
import { createPinia } from 'pinia'
// import { autoAnimatePlugin } from '@formkit/auto-animate/vue'


import '@quasar/extras/material-icons/material-icons.css'
import 'quasar/src/css/index.sass'

import App from '@/App.vue'
import routes from '@/router/routes.js'
import { createRouter, createWebHistory } from 'vue-router'

const pinia = createPinia()

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

const myApp = createApp(App)
myApp.use(pinia)


// myApp.use(autoAnimatePlugin)
myApp.use(router)

myApp.use(Quasar, {
  plugins: {Notify,},
  lang: quasarLang,
  config: {
    notify: { /* Думаю пригодится */ },
  },
  extras: [
    'mdi-v6',
  ]
})


myApp.mount('#app')

