/**
 * @author: Ruzin Vasiliy (@vruzin)
 *
 */
import { defineStore } from 'pinia'

import {ref, computed, watch, reactive,} from "vue"
import Configs from "@/Configs";
import { useAuthStore } from "@/components/Auth/AuthStore.js";

// { только для тестовых данных нужны
import { faker } from '@faker-js/faker/locale/ru';
import moment from  "moment/moment";
import {MakeNewColumns} from "../utils/Quasar/MakeNewColumns";
//   только для тестовых данных нужны }

const urls = { // Список роутов
  create: (data)=>({ method:"POST", url: "/api/v1/users", body: Object.assign(data, {id:null}) }),
  read  : (id)=>({ method:"GET", url: "/api/v1/users/"+id, }),
  update: (data)=>({ method:"POST", url: "/api/v1/users", body: data }),
  delete: (id)=>({ method:"DELETE", url: "/api/v1/users/"+id, }),
  list  : (page, per_page, search, sorting, filter)=>({ method: "GET",
    url: "/api/v1/users/?"+(new URLSearchParams({page, per_page, q: search, sorting, filter})).toString(),
  }),
}

export const useProjectsStore = defineStore('projects', arrayLike=>{
  const authStore = useAuthStore();

  const pagination = ref({
    sortBy: 'id',
    descending: false,
    page: 1,
    rowsPerPage: 25,
  });
  const rows = ref([]);

  /**
   * Массив объектов "колонок". (реактивный)
   *
   * Формат как по ссылке https://quasar.dev/vue-components/table#defining-the-columns
   * Но плюс еще параметры:
   * ```
   *    {
   *      show: true, //
   *      type: "checkbox"
   *    }
   * ```
   * Для работы достаточно поля `field`. Остальные колонки будут созданы автоматом благодаря функции `makeNewCol(...)`
   */
  const columns = ref(MakeNewColumns([
    { label: 'ID'            , field: 'id'               , type: 'id'                , goto: 'users:id', show: false,         },
    { label: 'Тип пользователя'        , field: 'auser_type_id'             , type: 'text'             , fake: ()=>faker.company.name(),},
    { label: 'Права доступа'  , field: 'rights'              , type: 'rights'                                   , fake: ()=>faker.name.findName(), },
    { label: 'bvz'        , field: 'created_at'       , type: 'datetime'     , show: false,  fake: ()=>faker.date.past(2), },
    { label: 'Создан'        , field: 'created_at'       , type: 'datetime'     , show: false,  fake: ()=>faker.date.past(2), },
    { label: 'Создан'        , field: 'created_at'       , type: 'datetime'     , show: false,  fake: ()=>faker.date.past(2), },
    { label: 'Создан'        , field: 'created_at'       , type: 'datetime'     , show: false,  fake: ()=>faker.date.past(2), },
    { label: 'Создан'        , field: 'created_at'       , type: 'datetime'     , show: false,  fake: ()=>faker.date.past(2), },
    { label: 'Изменён'       , field: 'updated_at'       , type: 'datetime'     , show: false,  fake: ()=>faker.date.past(1), },
  ],false,false));

  const fakeColumns = ()=> columns.value.filter(e=>e.fake!==undefined).reduce((acc, cur) =>{ acc[cur.field]=cur.fake();return acc; },{})
  // 100 фейковых проектов
  rows.value = Array.from({length: 10}, (i,n)=> Object.fromEntries(columns.value.map(e => ([e.field, e.field==='id' ? n: (e.fake ? e.fake() : '')]))));

  const rowKey = ref('id')
  const newRow = ref({});
  const SetNewRow = (RowValues)=>{
    let val = {
      id  : 'new',
      name: '',
      rop : '',
    }
    if(RowValues!==undefined){
      val.id   = RowValues.id || ''
      val.name = RowValues.name || ''
    }
    newRow.value = val
  }
  SetNewRow();

  const rowsPerPageOptions = ref([10,25,50,100,500,0])
  const loading = ref(false)

  const current = ref({});
  const SelectedProject = ref(null);

  const totalPages = computed(() => Math.ceil(pagination.value.rowsNumber / pagination.value.rowsPerPage))
  const visibleColumns = computed(() => columns.value.filter(e => e.show ?? true).map(i => i.field || i.name) )
  const columnsHideList = computed(() => columns.value.filter(e => e.type !== 'actions') )

  const toggleColumnIndex = (index) => { columns[index].show = !(columns[index].show || true) }

  const onRequest = async (props)=>{
    loading.value = true
    const { page, rowsPerPage, sortBy, descending } = props.pagination

    pagination.value.page =page
    pagination.value.rowsPerPage =rowsPerPage
    pagination.value.sortBy =sortBy
    pagination.value.descending =descending

    const filterReq = props.filter
    console.log('отправка')
    let v = urls.list(page<1?0:(page),
      rowsPerPage,
      filterReq,
      sortBy+":"+(descending?'desc':'asc'),
    )
    let data = await authStore.send(v.method,Configs.apiDomain + v.url, v.body)

    loading.value = false

    if (data.error===false && data.response.status===200){
      rows.value = data.result.items.map((e,i)=>Object.assign(e, fakeColumns(),{nid: (page-1)*rowsPerPage+i+1}))
      pagination.value.rowsNumber = data.result.totalCount || 0
    }
  }

  const Get = async (id)=>{
    loading.value = true

    let v = urls.read( id )
    let data = await authStore.send(v.method,Configs.apiDomain + v.url, v.body)
    loading.value = false
    if (data.error===false && data.response.status===200){
      current.value = data.result
    }
  }

  const reload = async ()=>{
    await onRequest({pagination:pagination.value,filter:filter.value})
  }

  const saving = ref(false)

  const save = async ()=>{
    saving.value = true
    let data2send = {
      name: newRow.value?.name || '',
      rop: newRow.value?.rop || '',
    }
    if(newRow.value.id !== null && newRow.value.id !== '' && newRow.value.id !== 'new'){
      data2send.id = newRow.value.id
    }
    let v = urls.update(data2send)
    let data = await authStore.send(v.method,Configs.apiDomain + v.url, v.body)
    await reload()
    saving.value = false
  }

  const deleting = ref(false)
  const del = async (id)=>{
    deleting.value = true
    let v = urls.delete(id)
    let data = await authStore.send(v.method,Configs.apiDomain + v.url, v.body)
    await reload()
    deleting.value = false
  }



  return {
    rows,
    rowKey,
    newRow,
    columns,
    pagination,
    totalPages,
    visibleColumns,
    loading,
    columnsHideList,
    rowsPerPageOptions,
    toggleColumnIndex,

    current,
    Get,

    save,
    saving,
    SetNewRow,
    onRequest,
    reload,
    del,
    deleting,
    SelectedProject,

  }
});
