<template>
  <q-btn dense flat no-wrap>
    <q-avatar rounded size="20px">
      <img src="https://cdn.quasar.dev/img/avatar6.jpg">
    </q-avatar>
    <q-icon name="arrow_drop_down" size="16px" />

    <q-menu auto-close style="max-width:300px">
      <q-list dense>
        <q-item class="GL__menu-link-signed-in">
          <q-item-section>
            <div><strong>{{ProjectsStore?.auth?.user?.fio}}</strong></div>
            <div class="text-blue-grey-5">{{ProjectsStore?.auth?.user?.email}}</div>
          </q-item-section>
        </q-item>
        <q-separator />
        <q-item clickable class="GL__menu-link" :to="{ name: 'UserView', params: {id: ProjectsStore?.auth?.user?.id } }"><q-item-section>Профиль</q-item-section></q-item>
        <q-item clickable class="GL__menu-link" :to="{ name: 'Users' }"><q-item-section>Все&nbsp;пользователи</q-item-section></q-item>
        <q-separator />

        <q-item clickable class="GL__menu-link" @click="authStore.logout()" to="/">
          <q-item-section>Выход</q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script setup>
import { useAuthStore } from "@/components/Auth/AuthStore.js";
import {useProjectsStore} from "@/stores/ProjectsStore.js";
const authStore = useAuthStore();
const ProjectsStore = useProjectsStore();
</script>

<style scoped>

</style>
