import Main from "@/pages/Main.vue";
import ProjectsRoutes from "@/pages/Projects/ProjectsRoutes";
import UsersRoutes from "@/pages/Users/UsersRoutes";
// import Projects from "@/pages/Projects/Projects.vue";
import ProjectsList from "@/pages/Projects/ProjectsList.vue";

// Lazy Load:
// const UserDetails = () => import('../pages/UserDetails.vue')

const routes = [
  // {
  //   path:      '/',
  //   components: {default: Main}
  // },
  {
    path:      '/',
    components: {default: ProjectsList}
  },
  ...ProjectsRoutes,
  ...UsersRoutes,
];

console.log('routes', routes);
export default routes
