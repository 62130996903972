import moment from "moment/dist/moment";
import ru from "moment/dist/locale/ru";

let sqlDate = "YYYY-MM-DD HH:mm:ss";
moment.locale('ru',ru);

const dateTimeFormat = (datetime)=>moment(datetime).calendar()

export default {
  sqlDate,
  tables: {
    dateTimeFormat,
  },
  // autoAnimate: {
  //   duration: 300,
  // },
  apiDomain: import.meta.env.PROD
               // ? "https://pfp.netsl.ru"
               ? ""
               : "http://localhost:3131",

};
