<script setup>
import SVGs from '@/SVGs.vue'
import Login from '@/components/Auth/Login.vue'
import ProfileMenuDropDown from '@/components/Auth/ProfileMenuDropDown.vue'
import { useAuthStore } from "@/components/Auth/AuthStore.js";
const authStore = useAuthStore();
import {useProjectsStore} from "@/stores/ProjectsStore";
import {log} from "@/utils/log.ts";
import {useRouter,useRoute} from "vue-router";

import moment from "moment/dist/moment";
import ru from "moment/dist/locale/ru";
moment.locale('ru',ru);

const projectsStore = useProjectsStore();
const router         = useRouter()
const route         = useRoute()
</script>

<template>
  <Login v-if="!authStore.logined"/>
  <q-layout view="hHh lpR fFf" v-else>

    <q-header bordered class="bg-primary text-white">
      <q-toolbar>
        <div>
          <router-link to="/" class="header-a">
            <span class="header-title">ПромфинПлан</span>
          </router-link>
        </div>
        <template v-if="route.name==='ProjectView'">
          <q-select filled dense
                    v-model="projectsStore.SelectedProject"
                    :options="projectsStore.projects
                        .filter(y=>( y.id===projectsStore.SelectedProject || [y.created_at.split('-')[0]-0,y.updated_at.split('-')[0]-0,y.start_at.split('-')[0]-0,y.end_at.split('-')[0]-0].indexOf((new Date()).getFullYear()) !== -1 ) )
                        .map(row => ({label: row.name + '<span class=\'header-select-rop\'>'+((e)=>e.fio)(projectsStore.users.find(e=>e.id===row.adm_user_id))+'</span>', value: row.id}))"
                    bg-color="white"


                    :options-html="true"
                    @update:model-value="projectsStore.SelectedProject = $event-0; projectsStore.api.get.url_params.id = $event;router.push({name:'ProjectView',params:{id:$event}});projectsStore.send('get')"
                    emit-value
                    map-options
                    class="header-select-project"
          >
            <q-tooltip>Переключить проект</q-tooltip>
          </q-select>

<!--          <div class="dt-from-to">-->
            <!--      <q-btn color="primary" icon="add" round size="lg" @click="projectsStore.addDetails()"/>-->
            <!--      <q-btn color="blue-grey-1" icon="remove" round size="lg" text-color="blue-grey-4"/>-->
            <!--      <q-space/>-->
            <q-btn  icon="chevron_left" flat style="margin-left: 10px;"
                   @click="
              projectsStore.project.start_at=moment(projectsStore.project?.start_at).subtract(1,'week').format('YYYY-MM-DD');
              projectsStore.send('save_project',{
                body: {
                  project: {
                    id: projectsStore.project.id,
                    start_at: projectsStore.project.start_at,
                    end_at: projectsStore.project.end_at,
                  },
                }
              });
             "><q-tooltip>Добавить неделю в начале</q-tooltip></q-btn>
            <!--      <q-input filled v-model="projectsStore.project.start_at" label="ОТ" style="width: 140px;" dense>
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer"/>
                        <q-popup-proxy transition-show="fade" transition-hide="fade">
                          <q-date v-model="projectsStore.project.start_at" mask="YYYY-MM-DD">
                            <div class="row items-center justify-end">
                              <q-btn v-close-popup label="Закрыть" color="primary" flat />
                            </div>
                          </q-date>
                        </q-popup-proxy>
                    </template>
                  </q-input>
                  <q-input filled v-model="projectsStore.project.end_at" label="ДО" style="width: 140px;" dense>
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer"/>
                        <q-popup-proxy transition-show="fade" transition-hide="fade">
                          <q-date v-model="projectsStore.project.end_at" mask="YYYY-MM-DD">
                            <div class="row items-center justify-end">
                              <q-btn v-close-popup label="Закрыть" color="primary" flat />
                            </div>
                          </q-date>
                        </q-popup-proxy>

                    </template>
                  </q-input>-->
            <q-btn  icon="chevron_right" flat
                   @click="
              projectsStore.project.end_at=moment(projectsStore.project.end_at).add(1,'week').format('YYYY-MM-DD');
              projectsStore.send('save_project',{
                body: {
                  project: {
                    id: projectsStore.project.id,
                    start_at: projectsStore.project.start_at,
                    end_at: projectsStore.project.end_at,
                  },
                }
              });
            "><q-tooltip>Добавить неделю в конце</q-tooltip></q-btn>
            <q-btn flat

                label="Перенести в базовый план"
                   @click="projectsStore.CopyToPlan()">
              <q-tooltip>Перенести значения из <b>Итог Факт+План</b> в <b>Базовый план</b></q-tooltip>
            </q-btn>
            <q-btn flat icon="file_download" @click="authStore.send('GET','/api/v1/project/'+projectsStore.project.id+'/xlsx', null, true)">
              <q-tooltip>Скачать Excel</q-tooltip>
            </q-btn>
<!--            <q-btn flat
                   icon="file_download"
                   :href="'/api/v1/project/'+projectsStore.project.id+'/xlsx'"
                   :download="projectsStore.project.name + '_'+moment().format('YYYY-MM-DD') +'.xlsx'"
            >
                <q-tooltip>Скачать Excel</q-tooltip>
            </q-btn>-->

<!--          </div>-->
        </template>
<!--        <q-btn flat label="Создать проект" v-if="route.name==='ProjectView'">
          <q-popup-proxy class="add_project_popup">
            <div class="add_project_title">Добавить проект</div>
            <div class="">
              <div class="q-gutter-md" style="width: 355px">
                <q-input v-model="projectsStore.new_project.name" label="Название" autofocus/>
                <div class="add_project_dates">
                  <q-input  v-model="projectsStore.new_project.start_at" label="Начало" style="width: 150px;" inline>
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer"/>
                      <q-popup-proxy transition-show="fade" transition-hide="fade">
                        <q-date v-model="projectsStore.new_project.start_at" mask="YYYY-MM-DD">
                          <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Закрыть" color="primary" flat />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </template>
                  </q-input>
                  <q-input  v-model="projectsStore.new_project.end_at" label="Конец" style="width: 150px;" inline>
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer"/>
                      <q-popup-proxy transition-show="fade" transition-hide="fade">
                        <q-date v-model="projectsStore.new_project.end_at" mask="YYYY-MM-DD">
                          <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Закрыть" color="primary" flat />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </template>
                  </q-input>
                </div>
                <q-select
                          v-model="projectsStore.new_project.adm_user_id"
                          :options="projectsStore.users.map(row => ({label: row.fio ||row.name, value: row.id}))"
                          label="Руководитель"
                          placeholder="Руководитель"
                          emit-value
                          map-options
                          >
                </q-select>
                <q-toolbar class="pad0 q-gutter-x-md ">
                  <q-btn label="СОХРАНИТЬ" color="primary" :loading="projectsStore.new_projectLoading" @click="projectsStore.send('add_project',{body: projectsStore.new_project})" v-close-popup/>
                  <q-btn label="ОТМЕНИТЬ" @click="projectsStore.resetAddProject()" v-close-popup/>
                </q-toolbar>
              </div>
            </div>
          </q-popup-proxy>
        </q-btn>-->
<!--        <q-space/>
        <q-btn label="Demo" flat @click="projectsStore.send('add_demo_data')" v-close-popup/>-->
        <q-space/>
        <ProfileMenuDropDown/>
      </q-toolbar>
    </q-header>

    <q-page-container>
      <div class="main-page">
        <div class="main-page_">
          <router-view />
        </div>
        <div class="footer-page">
          <router-link to="/" class="footer-a">
            <span class="svg-icon svg-icon-logo"><svg><use xlink:href="#svg-logo"></use></svg></span>
          </router-link>
          © 2023
          — <a href="https://netsl.ru" class="footer-a">NetSL.ru</a>
        </div>
      </div>

    </q-page-container>

  </q-layout>


  <SVGs/>
</template>

<style lang="stylus">
.main-page{min-height: calc(100vh - 52px);display:flex;flex-direction:column;gap:30px}
.main-page_{flex:1 0 auto}
.footer-page{margin:0px 0 30px 0;color:#A6A6A6;
  a{color:#A6A6A6;text-decoration:none;}
}
.footer-a{margin-right:32px;}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  //margin-top: 60px;
}

.header-a{color:#fff;text-decoration:none;}
.header-title{font-size:20px;vertical-align: middle;margin: 0 20px 0 12px;}


.svg-icon{display: inline-flex;align-content: center;justify-content: center;vertical-align: middle;}
.svg-icon-logo{background:#fff;}
.svg-icon-logo svg{width:100px;height:33px}

.header-select-project{min-width:200px;}
.header-select-rop{color: #999;margin-left:12px}
.page-title{font-size:24px;}

.add_project_popup{padding:4px 16px;}
.add_project_title{font-size:18px;margin: 12px 12px 12px 0; }
.add_project_dates{display:flex;gap:32px;}
</style>
