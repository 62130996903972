/**
 * @author: Ruzin Vasiliy (@vruzin)
 *
 */
import { defineStore } from 'pinia'
import {ref,computed,} from "vue"
import { LocalStorage, SessionStorage } from 'quasar'
import Configs from "@/Configs";
import {useProjectsStore} from "@/stores/UsersStore.js";
import moment from "moment/moment";
import FileSaver from 'file-saver';

const urls = { // Список роутов
  login: Configs.apiDomain + "/api/v1/auth/login",
  read  : "",
  update: "",
  delete: "",
  list  : "",
}

export const useAuthStore = defineStore('auth', ()=>{

  const logined = ref(false)
  const loading = ref(false)
  const email = ref("demo@demo.ru")
  const password = ref("123")
  const password2 = ref("")
  const remember = ref(true)
  const action = ref('')
  const accessToken = ref("")
  // const refreshToken = ref('')

  const user =ref({
    id: 0,
    fio: '',
    email: '',
    role: 0,
  })


  let at = LocalStorage.getItem('access_token')
  if (at){
    accessToken.value = at
    logined.value = true
  }
  // let rt = LocalStorage.getItem('refresh_token')
  // if (rt){
  //   refreshToken.value = rt
  // }

  const Authentication = computed(()=>{
    console.log("AuthStore.js::50", accessToken.value);
    if(accessToken.value===undefined){
      let at = LocalStorage.getItem('access_token')
      if (at){
        accessToken.value = at
      }
    }
    return 'Bearer '+accessToken.value
  })




  const sendLogin = async ()=>{
    const ProjectsStore = useProjectsStore();
    loading.value = true;
    try{
      // debugger
      let response = await fetch(urls.login, {
        method: 'POST',
        headers: {
          // Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: Authentication.value,
        },
        body: JSON.stringify({
          "login": {
            "email":    email.value,
            "password": password.value,
          }
        })
      });

      loading.value = false;
      try{
        let result = await response.json();
        // if( result.deviceId !== undefined && result?.tokens?.accessToken !== undefined && result?.tokens?.refreshToken !== undefined ) {
        if( result?.auth?.is_auth===true) {
          logined.value = true;
          ProjectsStore.auth = result.auth
          if (result.auth?.token!==undefined && result.auth?.token!==null && result.auth?.token!== '') {
            accessToken.value = result.auth.token;
            LocalStorage.set('access_token', accessToken.value)
            ProjectsStore.send('load')
          }
          // ProjectsStore.send('load')
          // debugger
          // refreshToken.value = result.tokens.refreshToken;
          // LocalStorage.set('refresh_token', refreshToken.value)
        }else{
          logined.value = false;
          accessToken.value = ""
        }
      }catch(e){
        logined.value = false;
        console.log('Error JSON',e);
      }
    }catch(e){
      loading.value = false;
      logined.value = false;
      console.log('Error send',e);
    }
  }


  const submit = async (event)=>{
    switch(true){
      case action.value==='': // войти
        await sendLogin();
        break;
      case action.value==='forgot': // восстановить
        action.value=''
        break;
    }
    event.preventDefault();
    return false;
  }


  const logout = async (event)=>{
    const ProjectsStore = useProjectsStore();

    logined.value = false;
    accessToken.value = "";
    LocalStorage.set('access_token', "")
    // refreshToken.value = "";
    ProjectsStore.auth = {};
    event?.preventDefault();
    return false;
  }

  function getCookie(c_name) {
    if (document.cookie.length > 0) {
      let c_start = document.cookie.indexOf(c_name + "=");
      if (c_start != -1) {
        c_start = c_start + c_name.length + 1;
        let c_end = document.cookie.indexOf(";", c_start);
        if (c_end == -1) {
          c_end = document.cookie.length;
        }
        return unescape(document.cookie.substring(c_start, c_end));
      }
    }
    return "";
  }

  /**
   * Делает запрос к беку, и подставляет нужные заголовки для авторизации.
   *
   * @param method
   * @param url
   * @param data
   * @param download
   * @returns {Promise<{result: boolean, error: boolean}>}
   */
  const send = async (method, url, data = null, downloadIt = false)=>{
    const ProjectsStore = useProjectsStore();
    let error = false
    let result = false

    let response
    try{
      let opt = {
        method: method,
        headers: {
          // Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: Authentication.value,
        },
      }
      if( data !== undefined && data !== null) {
        opt.body = JSON.stringify(data)
      }
      // if(download) {
      //   response = await fetch(url, opt).then(function(resp) {
      //     return resp.blob();
      //   }).then(function(blob) {
      //     download(blob);
      //   })
      // }
      response = await fetch(url, opt);
      if(downloadIt) {
        const blob = await response.blob();
        // const file = window.URL.createObjectURL(blob);
        // window.location.assign(file);
        let file = new File([blob], ProjectsStore.project.name + '_'+moment().format('YYYY-MM-DD') +'.xlsx',
            {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
        FileSaver.saveAs(file);
        return;
      }else if (method!=='DELETE'){
        try{
          result = await response.json();
          // debugger
        }catch(e){
          error = {text:'Error JSON',err: e};
          console.log('Error JSON',e);
          // logout(); // скорее всего слетела авторизация. Хотя не понятно в чем дело, нет ошибок
        }
      }
    }catch(e){
      error = {text:'Error send',err: e};
      console.log('Error send',e);
    }
    if( result?.auth !== undefined) {
      if( result.auth?.is_auth===true) {
        logined.value = true;
        ProjectsStore.auth = result.auth
        if (result.auth?.token!==undefined && result.auth?.token!==null && result.auth?.token!== '') {
          accessToken.value = result.auth.token;
          LocalStorage.set('access_token', accessToken.value)
        }
        // ProjectsStore.send('load')
        // debugger
        // refreshToken.value = result.tokens.refreshToken;
        // LocalStorage.set('refresh_token', refreshToken.value)
      }else{
        logined.value = false;
        accessToken.value = ""
      }
    }
    // debugger
    // if (response.status!==200){
    if (result.type==="ERR_UNAUTHORIZED"){
      error = {text: result.type, err: result.message};
      logout();
    }
    return {
      error,
      response:{
        status: response.status,
        statusText: response.statusText,
        ok: response.ok,
        redirected: response.redirected,
      },
      result
    }
  }

  return {
    logined,
    loading,
    // login,
    email,
    password,
    password2,
    remember,
    action,
    submit,
    accessToken,
    // refreshToken,
    Authentication,
    sendLogin,
    logout,

    send,
    user,
  }
});
