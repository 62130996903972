<template>
  <td
      :class="[
          'td-input',
          'td-input__number',
          color ?'vr-table-plan__td_color_'+color : '',
          'vr-table-plan__td_wk_'+thisValue.wk,
          {
            'calc-bad-value':thisValue.v<0
          }
          ]"
      @contextmenu="handler($event)"
  >
    {{
      thisValue.v===0
          ? ''
          : IntValue(thisValue.v)
    }}
  </td>
</template>

<script setup>
import {get,set} from 'lodash';
import {computed, onMounted, ref} from "vue";
import {IntValue} from "@/utils/IntValue";
import {log} from "../utils/log";
import {useProjectsStore} from "@/stores/UsersStore";
const projectsStore = useProjectsStore();

const props = defineProps({
  store: {type: Object, default: ()=>({}),},
  year:  {type: [String,Number], default: "", },
  week:  {type: [String,Number], default: "", },
  keyPath: {type: [String,Array], default: "", },
  val:{ type:String, default:''},
  clr:{type:String,default:''},
});
const thisValue = computed(()=>{
  let va = get(projectsStore.itogi,props.keyPath[1]+'.'+props.keyPath[2]);
  if(props.val[0] === 'f'){
    // console.log("gTdFunc.vue::34 ", v);
    return {
      v:    va === undefined ? 0 : va[
          {
            f1: 'zp',
            f2: 'cmd',
            f3: 'rashod',
            f4: 'delta',
            f5: 'balance',
          }[props.val]
          ]
      , wk: va?.wk||2
    }
  }

  let v = get(props.store,props.keyPath);
  return {
    v: v === undefined ? 0 /* : props.val[0] === 'f'
         ? ({
         f1: (f)=>(((f?.d1||0)-0)/4*props.store?.zp1)+     (((f?.d2||0)-0)/4*props.store?.zp2)+     (((f?.d3||0)-0)/4*props.store?.zp3) ,
         f2: (f)=>(((f?.d1||0)-0)*5*props.store?.command1)+(((f?.d2||0)-0)*5*props.store?.command2)+(((f?.d3||0)-0)*5*props.store?.command3) ,
         f3: (f)=>(((f?.d1||0)-0)/4*props.store?.zp1)+(((f?.d2||0)-0)/4*props.store?.zp2)+(((f?.d3||0)-0)/4*props.store?.zp3)
         + (((f?.d1||0)-0)*5*props.store?.command1)+(((f?.d2||0)-0)*5*props.store?.command2)+(((f?.d3||0)-0)*5*props.store?.command3)
         +((f?.v2||0)-0)
         +((f?.v3||0)-0)
         +((f?.v4||0)-0)
         +((f?.v5||0)-0)
         +((f?.v6||0)-0)
         +((f?.v7||0)-0)
         +((f?.v8||0)-0)
         +((f?.v9||0)-0)
         +((f?.v10||0)-0)
         ,
         f4: (f)=>((f?.v1||0)-0) - (((f?.d1||0)-0)/4*props.store?.zp1)+(((f?.d2||0)-0)/4*props.store?.zp2)+(((f?.d3||0)-0)/4*props.store?.zp3)
         - (((f?.d1||0)-0)*5*props.store?.command1)+(((f?.d2||0)-0)*5*props.store?.command2)+(((f?.d3||0)-0)*5*props.store?.command3)
         -((f?.v2||0)-0)
         -((f?.v3||0)-0)
         -((f?.v4||0)-0)
         -((f?.v5||0)-0)
         -((f?.v6||0)-0)
         -((f?.v7||0)-0)
         -((f?.v8||0)-0)
         -((f?.v9||0)-0)
         -((f?.v10||0)-0)
         ,
         f5: (f)=>((f?.v1||0)-0) - (((f?.d1||0)-0)/4*props.store?.zp1)+(((f?.d2||0)-0)/4*props.store?.zp2)+(((f?.d3||0)-0)/4*props.store?.zp3)
         - (((f?.d1||0)-0)*5*props.store?.command1)+(((f?.d2||0)-0)*5*props.store?.command2)+(((f?.d3||0)-0)*5*props.store?.command3)
         -((f?.v2||0)-0)
         -((f?.v3||0)-0)
         -((f?.v4||0)-0)
         -((f?.v5||0)-0)
         -((f?.v6||0)-0)
         -((f?.v7||0)-0)
         -((f?.v8||0)-0)
         -((f?.v9||0)-0)
         -((f?.v10||0)-0)
         ,
         }[props.val])(v)*/
           : (v[props.val] || 0)
    , wk: va?.wk??2
  }
})

</script>

<style lang="stylus">

</style>
