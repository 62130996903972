<template>
  <div class="main">

    <div class="wr">
      <div class="q-pa-md">
        <div class="q-gutter-md row" style="padding-right:100px;padding-bottom:10px;">
          <q-space/>
          <q-btn color="primary" label="Создать пользователя">
            <q-popup-proxy class="add_project_popup">
              <div class="add_project_title">Добавить пользователя</div>
              <div class="">
                <div class="q-gutter-md" style="width: 355px">
                  <q-input v-model="projectsStore.new_user.fio" label="ФИО" autofocus/>
                  <q-input v-model="projectsStore.new_user.email" label="E-mail"/>
                  <q-input v-model="projectsStore.new_user.password" label="Пароль" type="password"/>
                  <q-input v-model="projectsStore.new_user.note" label="Примечание" type="textarea" autogrow/>
                  <q-checkbox v-model="projectsStore.new_user.is_admin" label="Администратор">
                    <q-tooltip>Тот кто может раздавать права доступа и изменять пароли пользователей</q-tooltip>
                  </q-checkbox>
                  <q-checkbox v-model="projectsStore.new_user.is_published" label="Активирован">
                    <q-tooltip>Тот кто может раздавать права доступа и изменять пароли пользователей</q-tooltip>
                  </q-checkbox>

                  <q-toolbar class="pad0 q-gutter-x-md ">
                    <q-btn label="СОХРАНИТЬ"
                           color="primary"
                           :loading="projectsStore.new_userLoading"
                           @click="
                            projectsStore.new_user.id = 0;
                            projectsStore.send('set_user',{body: pick(projectsStore.new_user, 'id,fio,email,passwd,note,is_admin,is_published'.split(','))});
                            projectsStore.resetSetUser();"
                           v-close-popup/>
                    <q-btn label="ОТМЕНИТЬ" @click="projectsStore.resetSetUser()" v-close-popup/>
                  </q-toolbar>
                </div>
              </div>
            </q-popup-proxy>
          </q-btn>
          <q-space/>
        </div>
      </div>
      <q-markup-table flat bordered class="my-t">
        <thead>
        <tr>
          <th class="text-left">ФИО</th>
          <th class="text-left">E-mail</th>
          <th class="text-left">Активен</th>
          <th class="text-left">Админ</th>
          <th class="text-left">Примечание</th>
          <th class="text-left">Руководитель в проектах</th>
          <th class="text-left">Создан</th>
          <th class="text-left">Изменен</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(i, index) in projectsStore.users">
          <td class="text-left"><router-link :to="{ name: 'UserView', params: { id: i.id }}">{{i.id}}. {{i.fio}}</router-link></td>
          <td class="text-left"><router-link :to="{ name: 'UserView', params: { id: i.id }}">{{i.email}}</router-link></td>
          <td class="text-left"><q-checkbox v-model="i.is_published" disable/></td>
          <td class="text-left"><q-checkbox v-model="i.is_admin" disable/></td>
          <td class="text-left">{{i.note}}</td>
          <td class="text-left wrap user_projects">
            <div>
              <router-link
                  v-for="p in (projectsStore.projects||[]).filter(pp=>pp.adm_user_id===i.id)"
                  :key="'p'+p.id+'u'+i.id"
                  :to="{ name: 'ProjectView', params: { id: p.id }}"
                  class="user_project"
              >{{p.name}} </router-link>
            </div>
          </td>
          <td class="text-left"><router-link :to="{ name: 'UserView', params: { id: i.id }}">{{ moment(i.created_at).calendar() }}</router-link></td>
          <td class="text-left"><router-link :to="{ name: 'UserView', params: { id: i.id }}">{{ moment(i.updated_at).calendar() }}</router-link></td>
        </tr>
        </tbody>
      </q-markup-table>
    </div>
  </div>
</template>

<script setup>
import moment from "moment/dist/moment";
import ru from "moment/dist/locale/ru";
import { ref, onMounted } from 'vue'
import {pick} from "lodash";
// import VehiclesAdd from "@/pages/Vehicles/VehiclesAdd.vue";
import GTd from "@/components/gTd.vue";
import TableFilter from "@/components/TableFilter.vue";
import {useProjectsStore} from "@/stores/ProjectsStore";
const projectsStore = useProjectsStore();
const tableRef = ref()
const selectedYear = ref('')

moment.locale('ru',ru);

onMounted(() => {
  // tableRef.value.requestServerInteraction()
})
const load = ()=>{
  // tableRef.value.requestServerInteraction()
}
</script>

<style lang="stylus">
.user_projects{
  padding:4px 0 0 0!important;
}
.user_project {
  display: inline-block!important;
  background: #ccc;
  color: #666;
  transition: all 0.2s ease-in-out;
  font-size:12px;
  padding:1px 6px!important;
  border-radius: 4px;
  margin:0px 4px 4px 0!important
  text-decoration:none;
  &:hover{background: #009640; color: #fff;}
}
.wrap{white-space: normal!important;}
</style>
