<template>
  <div class="main">

    <div class="wr">
      <div class="q-pa-md">
        <div class="q-gutter-md row" style="padding-right:100px;padding-bottom:10px;">
          <q-select label="Год" v-model="selectedYear" :options="projectsStore.years" class="pr-year">
            <q-tooltip><span class="pr-year-tip">Год, когда был создан/изменен проект, или старовал/закончился проект.</span></q-tooltip>
          </q-select>
          <q-space/>
          <q-btn color="primary" label="Создать проект">
            <q-popup-proxy class="add_project_popup">
              <div class="add_project_title">Добавить проект</div>
              <div class="">
                <div class="q-gutter-md" style="width: 355px">
                  <q-input v-model="projectsStore.new_project.name" label="Название" autofocus/>
                  <div class="add_project_dates">
                    <q-input  v-model="projectsStore.new_project.start_at" label="Начало" style="width: 150px;" inline>
                      <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer"/>
                        <q-popup-proxy transition-show="fade" transition-hide="fade">
                          <q-date v-model="projectsStore.new_project.start_at" mask="YYYY-MM-DD">
                            <div class="row items-center justify-end">
                              <q-btn v-close-popup label="Закрыть" color="primary" flat />
                            </div>
                          </q-date>
                        </q-popup-proxy>
                      </template>
                    </q-input>
                    <q-input  v-model="projectsStore.new_project.end_at" label="Конец" style="width: 150px;" inline>
                      <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer"/>
                        <q-popup-proxy transition-show="fade" transition-hide="fade">
                          <q-date v-model="projectsStore.new_project.end_at" mask="YYYY-MM-DD">
                            <div class="row items-center justify-end">
                              <q-btn v-close-popup label="Закрыть" color="primary" flat />
                            </div>
                          </q-date>
                        </q-popup-proxy>
                      </template>
                    </q-input>
                  </div>
                  <q-select
                      v-model="projectsStore.new_project.adm_user_id"
                      :options="projectsStore.users.map(row => ({label: row.fio, value: row.id}))"
                      label="Руководитель"
                      placeholder="Руководитель"
                      emit-value
                      map-options
                  >
                  </q-select>
                  <q-toolbar class="pad0 q-gutter-x-md ">
                    <q-btn
                        label="СОХРАНИТЬ"
                        color="primary"
                        :loading="projectsStore.new_projectLoading"
                        @click="
                          projectsStore.send('add_project',{body: projectsStore.new_project})
                        "
                        :disabled="moment(projectsStore.new_project.end_at).isBefore(projectsStore.new_project.start_at)"
                        v-close-popup
                    />
                    <q-btn label="ОТМЕНИТЬ" @click="projectsStore.resetAddProject()" v-close-popup/>
                  </q-toolbar>
                </div>
              </div>
            </q-popup-proxy>
          </q-btn>
          <q-space/>
        </div>
      </div>
      <q-markup-table flat bordered class="my-t">
        <thead>
        <tr>
          <th class="text-left">Название</th>
          <th class="text-left">Руководитель</th>
          <th class="text-left">Примечание</th>
          <th class="text-left">Создан</th>
          <th class="text-left">Изменен</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(i, index) in (projectsStore.projects||[])
                                    .filter(y=>selectedYear===''?true
                                    : (
                                        (y.created_at.split('-')[0]==selectedYear)
                                        ||
                                        (y.updated_at.split('-')[0]==selectedYear)
                                        ||
                                        (y.start_at.split('-')[0]==selectedYear)
                                        ||
                                        (y.end_at.split('-')[0]==selectedYear)
                                      )
                                  )">
          <td class="text-left"><router-link :to="{ name: 'ProjectView', params: { id: i.id }}">{{index+1}}. {{i.name}}</router-link></td>
          <td class="text-left"><router-link :to="{ name: 'ProjectView', params: { id: i.id }}">{{((e)=>e.fio)(projectsStore.users.find(e=>e.id===i.adm_user_id))}}</router-link></td>
          <td class="text-left"><router-link :to="{ name: 'ProjectView', params: { id: i.id }}">{{i.note}}</router-link></td>
          <td class="text-left"><router-link :to="{ name: 'ProjectView', params: { id: i.id }}">{{ moment(i.created_at).calendar() }}</router-link></td>
          <td class="text-left"><router-link :to="{ name: 'ProjectView', params: { id: i.id }}">{{ moment(i.updated_at).calendar() }}</router-link></td>
        </tr>
        </tbody>
      </q-markup-table>
    </div>
  </div>
</template>

<script setup>
import moment from "moment/dist/moment";
import ru from "moment/dist/locale/ru";
import { ref, onMounted } from 'vue'
// import VehiclesAdd from "@/pages/Vehicles/VehiclesAdd.vue";
import GTd from "@/components/gTd.vue";
import TableFilter from "@/components/TableFilter.vue";
import {useProjectsStore} from "@/stores/ProjectsStore";
const projectsStore = useProjectsStore();
const tableRef = ref()
const selectedYear = ref('')

moment.locale('ru',ru);

onMounted(() => {
  // tableRef.value.requestServerInteraction()
})
const load = ()=>{
  // tableRef.value.requestServerInteraction()
}
</script>

<style lang="sass">
.pr-year
  width: 80px
.pr-year-tip
  font-size: 16px
.wr
  width: 80%
  margin: 0 auto
  padding: 0 16px
.my-t

  td
    padding: 0
    margin: 0
    height: auto !important
  a
    color: #000
    text-decoration: none
    display: block
    padding: 8px 16px
    margin: 0
.q-table-cms
  max-height: 80vh
  tr th
    color: #000
    position: sticky
    z-index: 2
    background: #fff
    top: 0
  .scroll
    color: transparent
    transition: color 0.3s linear
    &:hover
      color: #ccc
    &>*
      color: #606266
    &::-webkit-scrollbar
      height: 4px
      width: 4px
      margin: 1px
      transition: all 0.3s linear
      &:hover
        height: 12px
        width: 12px
    &::-webkit-scrollbar-track
      background: transparent
    &::-webkit-scrollbar-thumb
      background-color: currentColor
      border-radius: 4px

.q-table-cms .q-table>thead>tr>th
  padding-top: 13px
  padding-bottom: 13px

.title
  font-size: 22px

.my-sticky-header-column-table
  /* height or max-height is important */
  height: 100%

  /* specifying max-width so the example can
    highlight the sticky column on any browser window */
  max-width: 100%

  td:first-child,th:first-child
    width: 30px
    text-align: center
    background-color: #ddd !important
    padding: 0!important
  td:nth-child(2)
    /* bg color is important for td; just specify one */
    background-color: #f4f4f4 !important

  tr th
    position: sticky
    /* higher than z-index for td below */
    z-index: 2
    /* bg color is important; just specify one */
    background: #fff

  /* this will be the loading indicator */
  thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
    /* highest z-index */
    z-index: 3
  thead tr:first-child th
    top: 0
    z-index: 1
  tr:first-child th:first-child
    /* highest z-index */
    z-index: 3

  td:first-child
    z-index: 1

  td:first-child, th:first-child,td:nth-child(2), th:nth-child(2)
    position: sticky
    left: 0
  td
    vertical-align: top
    max-width: 300px
    white-space: normal
    line-height: 1
    position: relative
    padding: 0!important
    &.td-id
      text-align: right
      padding: 4px 4px 0 0!important
      width: 50px!important
      font-size: 10px
      color: #ccc
      &:hover
        background-color: #e6f4ff
        color: #000
    &.td-number
      text-align: right
      padding: 4px 4px 0 0!important
      width: 50px!important
      color: #666
      &:hover
        background-color: #e6f4ff
        color: #000
    &.td-date
      text-align: center
      padding: 1px!important
      width: 50px!important
      font-size: 11px
      color: #666
      &:hover
        background-color: #e6f4ff
        color: #000
    &.td-html
      padding: 4px 4px 0 0!important

      &>.td-html-div
        position: relative
        &>.td-html-str
          padding: 4px 8px
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
        &:hover
          background-color: #e6f4ff
          color: #000

        &>.td-html-prev
          overflow: auto
          position: absolute
          left: 60px
          top: 100%
          border: 2px solid #999
          background-color: #fff
          color: #000
          z-index: 3
          max-width: 800px
          width: 80vw
          max-height: 500px
          white-space: normal
          display: none
          padding: 6px
        &:hover>.td-html-prev
          display: block
      &:hover
        background-color: #e6f4ff
        color: #000
    &.td-edited:before
      content: ""
      position: absolute
      left: calc(100% - 8px)
      top: 0
      display: block
      width: 0
      height: 0
      border-style: solid
      border-width: 0 8px 8px 0
      border-color: transparent #ff0000 transparent transparent
      z-index: 3

    &.td-bool
      text-align: center
      padding: 0!important
      width: 24px!important
      &:hover
        background-color: #e6f4ff
    &>.small-value
      padding: 4px 8px
      position: absolute
      left: 0
      right: 0
      top: 0
      bottom: 0
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      &:hover
        background-color: #e6f4ff
        right: auto
        bottom: auto
        z-index: 1
        max-width: 400px
        overflow: auto
        max-height: 500px
        white-space: normal



.svg-icon-download,.svg-icon-eye
  svg
    width: 24px
    height: 24px
    fill: $gray4

.td-tag .q-badge
  margin: 0 2px 2px 0

.td-actions .q-badge
  margin: 0 2px 2px 0
  color: $gray4
  cursor: pointer
  .svg-icon
    width: 12px
    height: 12px
    margin-right: 6px
    svg
      width: 12px
      height: 12px
      fill: $gray3




.td-actions-btn
  color: $gray3
  cursor: pointer
  display: inline-flex
  align-items: center
  justify-content: center
  gap: 6px
  padding: 2px 6px
  border-radius: 4px
  &:hover
    background: #f7f7f7
  .svg-icon-edit svg
    fill: #0D90D3
  .svg-icon-del svg
    fill: #F26E6E
  .svg-icon
    width: 16px
    height: 16px
    svg
      width: 16px
      height: 16px

.td-actions-hide
  display: none

.th-actions
  width: 1px!important
  padding: 0!important
  position: -webkit-sticky!important
  position: sticky!important
  left: calc(100% - 24px)
  z-index: 1

.td-actions2
  width: 1px
  //position: relative
  position: -webkit-sticky!important
  position: sticky!important
  left: calc(100% - 0px)
  z-index: 1
  padding: 0!important
  &>div
    background: #fff
    position: absolute
    top: 0px
    right: 0px
    display: flex
    align-items: center
    justify-content: center
    padding: 2px 2px
    z-index: 2
    gap: 12px

  &:hover
    &>div
      background: #dfdfdf
      padding: 2px 2px 2px 12px
    .td-actions-hide
      display: block
      &.td-actions-btn
        display: inline-flex


.bg-gray2
  background-color: $gray2
  color: $gray3

.search-inp
  .q-field__marginal,.q-field__control
    height: auto!important

.q-table-cols-toggle
  white-space: nowrap
  padding: 0 12px 0 0
  .q-checkbox
    display: flex


</style>
