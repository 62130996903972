/**
 * Обертка для колонок для формирования правильной структуры для quasar для qTable
 *
 * @param cols {[]abject} массив колонок
 *
 * @example
 *  ```
 *  const columns = ref(MakeNewColumns([
 *     { label: 'ID'             , field: 'Id'         ,show: false, },
 *     { label: 'ID компании'    , field: 'companyId'  , },
 *     { label: 'Название'       , field: 'name'       , },
 *     { label: 'Разрешения'     , field: 'permissions', type: 'permissions', },
 *   ]))
 *  ```
 */
const MakeNewColumns = (cols, makeActionsColumn=true, makeLineColumn=true)=>{
  return [
    makeActionsColumn?{ label: '',  field: '__actions'  , type: 'actions' , headerClasses: "th-actions", show: true, name: "__actions" } : null,
    makeLineColumn   ?{ label: '#', field: 'nid'        , type: 'id'      , headerClasses: "th-id"     , show: true, name: "nid"       } : null,
    ...cols.map(e=>{
      e.show = e.show ?? true
      e.name = e.name ?? e.field
      e.label = e.label ?? ''
      e.align = e.align ?? 'left'
      e.type = e.type ?? (
        e.field.toLowerCase().endsWith('_at')
          ? 'datetime'
          : (
            e.field.toLowerCase() === 'id'
              ? 'id'
              : ''
          )
      )
      e.sortable = e.sortable ?? (['actions'].indexOf(e.type.toLowerCase())===-1)
      return e
    })
  ].filter(e=>e!==null);
};

export { MakeNewColumns };