/**
 * @author: Ruzin Vasiliy (@vruzin)
 *
 */
import { defineStore } from 'pinia'
import Configs from "@/Configs";

import {ref, computed, watch, reactive,} from "vue"
// import Configs from "@/Configs";
// import { useAuthStore } from "@/components/Auth/AuthStore.js";
// import moment from  "moment/moment";
import {API} from "@/utils/API.ts";
import {GetObjectPaths} from "@/utils/GetObjectPaths.ts";
import moment from "moment/dist/moment";
import ru from "moment/dist/locale/ru";
import {range, debounce, set, get} from 'lodash'
import {log} from "../utils/log";
// let sqlDate = "YYYY-MM-DD";
moment.locale('ru',ru);




export const useProjectsStore = defineStore('projects', ()=>{
  // const authStore = useAuthStore();
  const datas = API(
      { projects:[],
        project:{},
        users:[],
        auth:{},
        new_project:{},
        new_user:{
          id: 0,
          fio: '',
          email: '',
          passwd: '',
          note: '',
          is_admin: false,
          is_published: false,
        },
      },
      {
        load:{ preload:'projects,users,auth', url:'/api/v1/project/all', save_to: "=",},
        get:{ preload:'project', url:'/api/v1/project/:id',url_params:{id:0}, save_to: "=",},
        add_project:{ preload:'project', method:'POST', url:'/api/v1/project/add', body: {}, save_to: "=",},
        save_project:{ preload:'project', method:'POST', url:'/api/v1/project/save', body: {}, save_to: "=",},
        set_user:{ /*preload:'users',*/ method:'POST', url:'/api/v1/user/set', body: {}, save_to: "=",},
        get_user:{ /*preload:'user',*/ url:'/api/v1/user/:id',url_params:{id:0}, save_to: "=",},
        add_demo_data:{ preload:'project', url:'/api/v1/add-demo-data', save_to: "=",},
      },
    {
      domain: Configs.apiDomain
    })
  datas.projectStop.value = true // остановим загрузку сразу. Потом будем подгружать, когда будет
  // const dateFrom = ref('2022-01-05')
  // const dateTo = ref('2023-03-23')
  const current = ref({});
  const needSave = ref(false);

  const needSaveProject = ref(false);
  const SelectedProject = ref(null);
  const columns = ref([
    // { label: 'ID'             , field: 'Id'         ,show: false, },
    { label: 'Название'       , field: 'name', },
    { label: 'Руководитель'   , field: 'adm_user_id'   , type: 'adm_user' },
    { label: 'Примечание'     , field: 'note'      , },
    { label: 'Создан'         , field: 'crated_at'      , },
    { label: 'Изменен'        , field: 'updated_at'      , },
  ]);

  const years = computed(()=>{
    let y = {};
    (datas.projects.value||[]).forEach(e=>{
      y[e.created_at.split('-')[0]]=true
      y[e.updated_at.split('-')[0]]=true
      y[e.start_at.split('-')[0]]=true
      y[e.end_at.split('-')[0]]=true
    })
    return Object.keys(y)
  });
  function calcFntoCosts(fn){
    let v = datas?.project?.value?.cost===undefined || datas?.project?.cost===null
      ? 0
      : Object.entries(datas?.project?.value?.cost||[])
          .filter(y => y[0]!=='y0' )
          .map(y => Object.values(y[1]) )
          .flat();
    if (v === null || v===undefined || v===0){return 0;}
    // console.log(v)
    return v?.reduce((p, a) => p + (fn(a)-0), 0);
  }
  const totalRashod = computed(()=>calcFntoCosts((f)=>
    (((f?.d1||0)-0)*5*(datas.project.value?.command1||0))+
    (((f?.d2||0)-0)*5*(datas.project.value?.command2||0))+
    (((f?.d3||0)-0)*5*(datas.project.value?.command3||0))+

    (((f?.d1||0)-0)/4*(datas.project.value?.zp1||0))+
    (((f?.d2||0)-0)/4*(datas.project.value?.zp2||0))+
    (((f?.d3||0)-0)/4*(datas.project.value?.zp3||0))

    +((f?.v2||0)-0)
    +((f?.v3||0)-0)
    // +((f?.v4||0)-0)
    // +((f?.v5||0)-0)
    +((f?.v6||0)-0)
    +((f?.v7||0)-0)
    +((f?.v8||0)-0)
    +((f?.v9||0)-0)
    +((f?.v10||0)-0)
  ));

  const itogi = computed(()=>{
    let v = {}
    let years = range(moment(datas._project.value?.start_at).year(), moment(datas._project.value?.end_at).year()+1)
    let balance = 0
    const currentWeek = "y"+(moment().year()-0)+'.w'+(moment().week())
    let wk = 0;
    years.forEach(y=>{
      let weeks = range(
        (moment(datas._project.value?.start_at).year()==y ? moment(datas._project.value?.start_at).week() : 1 ),
        (moment(datas._project.value?.end_at).year()==y ? moment(datas._project.value?.end_at).week() : (WeeksInYear(moment(datas._project.value?.end_at).year())) )+1
      )
      weeks.forEach(w=>{
        let val = {}
        let path = "y"+y+".w"+w
        if(path===currentWeek){
          wk = 1
          val.wk = 1
        }else if (wk===0){
          val.wk = 0
        }
        let f = get(datas._project.value,"cost."+path)

        val.v4 = (((f?.d1||0)-0)/4*(datas._project.value?.zp1||0))+
                 (((f?.d2||0)-0)/4*(datas._project.value?.zp2||0))+
                 (((f?.d3||0)-0)/4*(datas._project.value?.zp3||0));
        val.zp = val.v4;

        val.v5 =  (((f?.d1||0)-0)*5*(datas._project.value?.command1||0))+
                  (((f?.d2||0)-0)*5*(datas._project.value?.command2||0))+
                  (((f?.d3||0)-0)*5*(datas._project.value?.command3||0));
        val.cmd = val.v5
        val.rashod = val.zp+val.cmd
                                    +((f?.v2||0)-0)
                                    +((f?.v3||0)-0)
                                    // +((f?.v4||0)-0) // Зарплата (РП+ПТО+мастера+монтажники)
                                    // +((f?.v5||0)-0) // Суточные
                                    +((f?.v6||0)-0)
                                    +((f?.v7||0)-0)
                                    +((f?.v8||0)-0)
                                    +((f?.v9||0)-0)
                                    +((f?.v10||0)-0)
        // val.rashod = val.v4
        val.delta = ((f?.v1||0)-0) - val.rashod
        balance += val.delta
        val.balance = balance
        set(v,path,val)
      })
    })
    console.log(v)
    return v
  })

  function WeeksInYear(year){
    let w = moment(year+"-12-28").isoWeek()
    console.log(year, w)
    return w
  }

  const totalZP = computed(()=>calcFntoCosts((f)=>
    (((f?.d1||0)-0)/4*((datas.project.value?.zp1||0)-0))+
    (((f?.d2||0)-0)/4*((datas.project.value?.zp2||0)-0))+
    (((f?.d3||0)-0)/4*((datas.project.value?.zp3||0)-0))
    ));
  const totalCommand = computed(()=>calcFntoCosts((f)=>
    (((f?.d1||0)-0)*5*(datas.project.value?.command1 || 0)) +
    (((f?.d2||0)-0)*5*(datas.project.value?.command2 || 0)) +
    (((f?.d3||0)-0)*5*(datas.project.value?.command3 || 0))
  ));
  const totalPrihod = computed(()=>calcFntoCosts((f)=>(f?.v1||0)-0));
  const totalPrihodMinusRashod = computed(()=>totalPrihod.value - totalRashod.value);

  datas.resetAddProject = ()=>{
    datas.new_project.value.name = '';
    datas.new_project.value.start_at = moment().subtract(moment().isoWeekday()-1+(7*4), 'days').format("YYYY-MM-DD");
    datas.new_project.value.end_at = moment().year()+'-12-31';
    datas.new_project.value.adm_user_id = '';
    datas.new_project.value.costs = [];
  }
  datas.resetAddProject();

  datas.resetSetUser = ()=>{
    datas.new_user.value.fio = '';
    datas.new_user.value.email = '';
    datas.new_user.value.password = '';
    datas.new_user.value.note = '';
    datas.new_user.value.is_admin = false;
    datas.new_user.value.is_published = false;
  }
  datas.resetSetUser();

  // datas.dateForm = dateFrom;
  // datas.dateTo = dateTo;
  datas.current = current;
  datas.columns = columns;
  datas.totalPrihod = totalPrihod;
  datas.totalPrihodMinusRashod = totalPrihodMinusRashod;
  datas.totalRashod = totalRashod;
  datas.totalZP = totalZP;
  datas.totalCommand = totalCommand;
  datas.needSave = needSave;
  datas.needSaveProject = needSaveProject;
  datas.SelectedProject = SelectedProject;
  datas.years = years;
  datas.itogi = itogi;

  let debounced = debounce(async ()=>{
    let save_data = {};
    if(datas.needSaveProject.value){ // сохраняем проект
      save_data.project = {
        id: datas.project.value.id,
        name: datas.project.value.name,
        start_at: datas.project.value.start_at,
        end_at: datas.project.value.end_at,
        adm_user_id: datas.project.value.adm_user_id,
        copied_to_base_plan: datas.project.value.copied_to_base_plan,
        zp1: datas.project.value.zp1,
        zp2: datas.project.value.zp2,
        zp3: datas.project.value.zp3,
        command1: datas.project.value.command1,
        command2: datas.project.value.command2,
        command3: datas.project.value.command3,
      }
    }

    if(datas.needSave.value){ // сохраняем проект
      save_data.costs = GetObjectPaths(datas.project.value.cost,
        {
          maxLevel:1,
          filter: (path, key, obj, acc)=>{
            return Object.keys(obj[key]).some(e=>e.startsWith('OLD___'))
          },
          push: (path, key, obj, acc)=>{
            return Object.assign(
              { project_id: datas.project.value.id, year: path[0].substr(1)-0, week: key.substr(1)-0 },
              Object.fromEntries(Object.entries(obj[key]).filter(e=>!e[0].startsWith('OLD___')).map(e=>[e[0],e[1]-0]))
            )
          },
        })
    }
    datas.send('save_project',{body: save_data})
    save_data.costs.forEach(e=>{
      Object.keys(e).filter(k=>['year','week','project_id'].indexOf(k)===-1).forEach(k=> {
        delete datas.project.value.cost['y'+e.year]['w'+e.week]['OLD___'+k]
      })
    });
    ['OLD___zp1','OLD___zp2','OLD___zp3',
      'OLD___command1','OLD___command2','OLD___command3'
      ].forEach(k=>{
        if(k in datas._project.value){
          delete datas._project.value[k]
        }
      })
  }, 2000, { 'maxWait': 5000 })

  datas.save = ()=>{
    if(datas.needSave.value || datas.needSaveProject.value){
      debounced()
    }
  };

  // watch(datas.needSave, (newValue, oldValue)=>{
  //   if(newValue!==oldValue && newValue){
  //     debounced()
  //   }
  // })
 /*
  const list2 = kk.ref
  const _list = ref({
    data: [],
    loaded: false,
    error: false,
    loading: false,
  })
  const loadingList = ref(false)
  const loadedList = ref(false)


  const loading = ref(false)
  const current = ref({});
  const SelectedProject = ref(null);

  const Get = async (id, loadingVariable)=>{
    if(loadingVariable===undefined){
      loadingVariable = loading
    }
    loadingVariable.value = true
    let data = await authStore.send('GET',Configs.apiDomain + '/api/v1/project/'+id)
    loadingVariable.value = false
    if (data.error===false && data.response.status===200){
      setData(data.result)
    }
  }

  const list = computed({
    async get(){
      if(!loadedList.value){
        await Get('all',loadingList)
      }
      return _list.value||[]
    }
  })
  function setData(result){
    if( result?.project !== undefined ){
      current.value = result.project
    }
    if( result?.projects !== undefined ){
      _list.value = result.projects
    }
  }

  const reload = async ()=>{
    await Get(current.value.id||'')
  }

  const saving = ref(false)

  const save = async ()=>{
    saving.value = true
    let data2send = {
      // name: newRow.value?.name || '',
      // rop: newRow.value?.rop || '',
    }
    if(newRow.value.id !== null && newRow.value.id !== '' && newRow.value.id !== 'new'){
      data2send.id = newRow.value.id
    }
    let v = urls.update(data2send)
    let data = await authStore.send(v.method,Configs.apiDomain + v.url, v.body)
    await reload()
    saving.value = false
  }

  const deleting = ref(false)
  const del = async (id)=>{
    deleting.value = true
    let v = urls.delete(id)
    let data = await authStore.send(v.method,Configs.apiDomain + v.url, v.body)
    await reload()
    deleting.value = false
  }



  return {
    list2,
    list,
    loading,
    current,
    Get,

    save,
    saving,
    reload,
    del,
    deleting,
    SelectedProject,

    dateFrom,
    dateTo,
  }*/

  function summa(row){

    // debugger
    return Object.entries(datas.project.value?.cost)
                 .filter(y => y[0]!=='y0' )
                 .map(y => Object.values(y[1])
                                 .map(w => w[row] || 0))
                 .flat()
                 .reduce((p, a) => p + (a-0), 0)

  }

  function CopyToPlan(){
    for (let i=1;i<=10;i++){
      let val = i===1
        ? datas.totalPrihod
        : summa('v'+i)
      set(datas.project.value,['cost','y0','w0','v'+i],val);
      set(datas.project.value,['cost','y0','w0','OLD___v'+i],val);
    }
    set(datas.project.value,['cost','y0','w0','v4'],datas.totalZP);
    set(datas.project.value,['cost','y0','w0','v5'],datas.totalCommand);
    datas.project.value.copied_to_base_plan=true;
    datas.needSaveProject.value=true;
    datas.needSave.value=true;
    datas.save();
  }
  datas.CopyToPlan=CopyToPlan
  return datas
});
