interface PathsFn {
    /**
     * Максимальная вложенность
     */
    maxLevel?: number;

    /**
     * Функция, которая будет вызвана, когда путь должен быть добавлен.
     */
    pushIf?: (path:string[], key:string, obj:any, acc: any[]) => boolean;

    /**
     * Функция, которая возвращает объект, который добавляется в результирующий массив.
     */
    push?: (path:string[], key:string, obj:any, acc: any[]) => any;

    /**
     * Функция, для фильтрации результатов.
     */
    filter?: (path:string[], key:string, obj:any, acc: any[]) => boolean;

    /**
     * функция при каких условиях требуется остановить дальнейшую обработку.
     * Например, если уже больше определенного числа записей, то стоит остановить
     * @param path
     * @param key
     * @param obj
     * @param acc
     */
    stopIf?: (path:string[], key:string, obj:any, acc: any[]) => boolean;

}
/**
 * Вернет все пути объекта с гибкими условиями
 *
 * @param obj {Object}
 * @param options {Object}
 * @returns {*[]}
 */
export function GetObjectPaths(obj:object,options:PathsFn={}) {
    let acc:any[] = [];
    let opt:PathsFn = Object.assign({
        maxLevel: 100,
        pushIf: (path, key, obj, acc)=>{return typeof obj[key] !== "object" || path.length>=opt.maxLevel},
        push: (path, key, obj, acc)=>{return {path: [...path, key], val: obj[key]}},
        filter: (path, key, obj, acc)=>{return true},
        stopIf: (path, key, obj, acc)=>{return false},
    },options);
    function f(obj:any, path:string[] = []) {
        for (let key in obj) {
            if (opt.stopIf(path, key, obj, acc)) {break;}
            if (opt.pushIf(path, key, obj, acc)) {
                if (opt.filter(path, key, obj, acc)) {
                    acc.push(opt.push(path, key, obj, acc));
                }
            } else {
                f(obj[key], [...path, key]);
            }
        }
    }
    f(obj);
    return acc
};
